import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../../../api";
import { Teacher } from "../../../domains/Teacher";
import { createError, HTTPErrors } from "../../../errors";

type UseFetchTeacherWithIdProps = {
  teacherId: string | null;
  sectionId: string;
};
export const useFetchTeacherWithId = ({
  teacherId,
  sectionId,
}: UseFetchTeacherWithIdProps) => {
  const urlWithResource = baseUrl({ teacherId, sectionId });

  return useQuery<Teacher, HTTPErrors>(
    cacheKeyOf({ teacherId, sectionId }),
    async () => {
      const response = await api.interruptGet(urlWithResource);
      if (response.ok) {
        const json = await response.json();
        return json.teacher as Teacher;
      }
      throw await createError(response);
    },
    {
      enabled: teacherId !== null, // 新規の場合teacherIdはnull。詳細APIへリクエストしない
      cacheTime: 0, // 最新な情報が必須なのでキャッシュしない
      refetchOnWindowFocus: false,
    },
  );
};

const baseSaveURLOf = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/teachers`;
type UseSaveTeacherProps = {
  sectionId: string;
  onError: () => void;
  onSuccess: () => void;
};

// id==nullは新規、idが存在すれば更新
type SaveTeacherParam = Omit<Teacher, "id" | "fullName"> & {
  id: string | null;
};

export const useSaveTeacher = ({
  onError,
  onSuccess,
  sectionId,
}: UseSaveTeacherProps) => {
  const baseURL = baseSaveURLOf(sectionId);

  return useMutation<void, HTTPErrors, SaveTeacherParam>(
    async (param) => {
      const baseParam = {
        first_name: param.firstName,
        last_name: param.lastName,
      };
      const response = param.id
        ? await api.patch(`${baseURL}/${param.id}`, {
            teacher: { ...baseParam, id: param.id },
          })
        : await api.post(baseURL, {
            teacher: { ...baseParam },
          });

      if (response.ok) {
        return;
      }
      throw await createError(response);
    },
    {
      onError,
      onSuccess,
    },
  );
};

type CacheKeyOfParams = {
  teacherId: string | null;
  sectionId: string;
};
const cacheKeyOf = ({ teacherId, sectionId }: CacheKeyOfParams) => [
  baseUrl({ teacherId, sectionId }),
];

const baseUrl = ({ teacherId, sectionId }: CacheKeyOfParams) =>
  `/api/v1/sections/${sectionId}/teachers/${teacherId ?? ""}`;
