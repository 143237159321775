import * as React from "react";
import styles from "./GuardianEmailForm.styles.scss";
import { useFormik } from "formik";
import Label from "../../../../components/atoms/Label";
import { Flex } from "../../../../components/atoms/Flex";
import Input from "../../../../components/atoms/Input";
import ErrorText from "../../../../components/atoms/ErrorText";
import { Button } from "@studyplus/boron-ui";
import * as yup from "yup";
import StudentInterface from "../../../../interfaces/StudentInterface";
import { useMutateGuardianEmails } from "./useGuardianEmailsApi";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { InputLeftIconGroup } from "../../../../components/atoms/Input";
import Icon from "../../../../components/atoms/Icon";

type Values = { email: string };
type GuardianEmailFormProps = {
  student: StudentInterface;
};
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("正しいメールアドレスを入力してください")
    .required("メールアドレスを入力してください"),
});
const initialValues = { email: "" };

export const GuardianEmailForm = ({ student }: GuardianEmailFormProps) => {
  const {
    mutate,
    isLoading: isMutating,
    error,
  } = useMutateGuardianEmails({
    studentId: student.id,
  });

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const onSubmit = (values: Values) => {
    mutate(values, {
      onSuccess: () => {
        showSuccessMessage("認証メールを送信しました");
      },
      onError: () => {
        showErrorMessage("認証メールを送信できませんでした");
      },
    });
  };

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  React.useEffect(() => {
    // isMutatingがtrue -> falseに変わってエラーもない場合は送信が完了したと判断してフォームをリセットする
    if (!isMutating && error === null) {
      form.resetForm();
    }
  }, [isMutating]);

  return (
    <>
      <form onSubmit={form.handleSubmit}>
        <Label isMute htmlFor="email">
          保護者のメールアドレス
        </Label>
        <Flex gap="1" marginTop="1.0rem">
          <div className={styles.emailFieldContainer}>
            <InputLeftIconGroup>
              <Icon name="icon-message" color="gray-darken-1" />
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="メールアドレスを入力"
                onChange={form.handleChange}
                isBlock
                value={form.values.email}
              />
            </InputLeftIconGroup>

            {form.touched.email && <ErrorText>{form.errors.email}</ErrorText>}
          </div>
          <Button
            type="submit"
            disabled={
              isMutating || (form.touched.email && Boolean(form.errors.email))
            }
            isLoading={isMutating}
            className={styles.submitButton}
          >
            認証メールを送信
          </Button>
        </Flex>
      </form>
    </>
  );
};
