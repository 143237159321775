import SectionInterface from "../../interfaces/SectionInterface";
import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import { createError, HTTPErrors } from "../../errors";
import { Drill } from "../../domains/Drill";

type Props = {
  section: SectionInterface;
  materialCode?: string;
};
type DrillResponse = {
  drill: Drill;
};
export const useFetchDrillDetail = (props: Props) => {
  return useQuery<Drill, HTTPErrors>(
    ["drills", props.section.id, props.materialCode],
    async () => {
      const res = await api.get(
        `/api/v1/sections/${props.section.id}/drill_learning_materials/${props.materialCode}`,
      );

      if (res.status === 200) {
        const json: DrillResponse = await res.json();
        return json.drill;
      }

      throw await createError(res);
    },
    {
      retry: 0, // 404の場合はリトライしたくないので
      enabled: props.materialCode !== undefined,
    },
  );
};
