import * as React from "react";
import { Button } from "@studyplus/boron-ui";
import styles from "./styles.scss";
import { TextArea } from "./TextArea";
import StudyRecordInterface, {
  StudyRecordCommentInterface,
} from "../../../../../../../interfaces/StudyRecordInterface";
import StudentInterface from "../../../../../../../interfaces/StudentInterface";
import { usePostTimelineComment } from "./usePostTimelineComment";
import { useFlashMessage } from "../../../../../../../hooks/useFlashMessage";

interface Props {
  studyRecord: StudyRecordInterface;
  student: StudentInterface;
  canSendComment: boolean;
  comment: string;
  onAddComment: (comment: StudyRecordCommentInterface) => void;
  setComment: React.Dispatch<React.SetStateAction<string>>;
}

export const TimelineCommentForm = ({
  student,
  studyRecord,
  canSendComment,
  comment,
  onAddComment,
  setComment,
}: Props) => {
  const { showErrorMessage } = useFlashMessage();
  const { mutate, isLoading } = usePostTimelineComment({
    studentId: student.id,
    studyRecordHashid: studyRecord.id,
    onSuccess: (postedComment) => {
      onAddComment(postedComment);
      setComment("");
    },
    onError: () => {
      showErrorMessage("コメントが送信できませんでした");
    },
  });

  const isDisabled = comment.trim() === "" || !canSendComment || isLoading;
  const isRestricted = studyRecord.restrictedAttributes.includes("comments");

  const handleClickButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (isDisabled) return;

    mutate(comment);
  };

  return (
    <form className={styles.root}>
      <TextArea
        comment={comment}
        onChangeComment={setComment}
        isRestricted={isRestricted}
        canSendComment={canSendComment}
      />
      <Button
        type="submit"
        disabled={isDisabled}
        className={styles.button}
        onClick={(e) => handleClickButton(e)}
      >
        送信
      </Button>
    </form>
  );
};
