import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import ApiClient from "../../api";
import { ContentCourse } from "../../domains/ContentCourse";
import ApiResponse from "../../interfaces/ApiResponse";
import { PaginationMetaData } from "../../interfaces/ApiResponse";
import { ContentUnit } from "../../domains/ContentUnit";
import { Content } from "../../domains/Content";
import { fromJson } from "../../domains/ContentCourse";
import ApiErrorResponseInterface from "../../interfaces/ApiErrorResponseInterface";
import { createError, HTTPErrors } from "../../errors";
import { HTTPMethod } from "../../api";
import { paths } from "../../lib/api/v1";

export type UseFetchCotentCoursesProps = {
  sectionId: string;
};

type ResponseData = ApiResponse<ContentCourse[]>;

export const useFetchSectionCotentCourses = ({
  sectionId,
}: UseFetchCotentCoursesProps) => {
  return useInfiniteQuery<ResponseData, HTTPErrors>(
    [`sections/${sectionId}/contents/courses`],
    async ({ pageParam = 1 }) => {
      const res = await ApiClient.get(
        `/api/v1/sections/${sectionId}/contents/courses?page=${pageParam}`,
      );
      if (res.ok) {
        const json: paths["/api/v1/sections/{section_id}/contents/courses"]["get"]["responses"]["200"]["content"]["application/json"] =
          await res.json();
        if (
          !json.contentCourses ||
          !Array.isArray(json.contentCourses.data) ||
          !json.contentCourses.meta
        ) {
          throw await createError(res);
        }

        return {
          meta: json.contentCourses.meta as PaginationMetaData,
          data: json.contentCourses.data.map((contentCourse) =>
            fromJson(contentCourse),
          ),
        };
      } else {
        throw await createError(res);
      }
    },
    {
      getNextPageParam: (lastPage) => {
        const currentPage = lastPage.meta.currentPage;
        const nextPage = currentPage + 1;
        const totalPages = lastPage.meta.totalPages;
        return nextPage > totalPages ? undefined : nextPage;
      },
      refetchOnWindowFocus: false,
    },
  );
};

type UseMutateContentCourseProps = {
  sectionId: string;
  courseId?: string;
};

type UnitRequestBody = {
  id: string | null;
  name: ContentUnit["name"];
  description: ContentUnit["description"];
  sequence: ContentUnit["sequence"];
  contents: {
    id: Content["id"];
    sequence: number;
  }[];
};
export type ContentCourseRequestBody = {
  name: ContentCourse["name"];
  description: ContentCourse["description"];
  thumbnail: File | null;
  units: UnitRequestBody[];
  start_at: ContentCourse["startAt"];
  end_at: ContentCourse["endAt"];
};

type MutateErrors = ApiErrorResponseInterface;
export const useMutateContentCourse = ({
  sectionId,
  courseId,
}: UseMutateContentCourseProps) => {
  const client = useQueryClient();

  return useMutation<
    ContentCourse | undefined,
    MutateErrors,
    ContentCourseRequestBody
  >(
    async (params: ContentCourseRequestBody) => {
      const { path, method } = courseId
        ? {
            path: `/api/v1/sections/${sectionId}/contents/courses/${courseId}`,
            method: "PATCH" as HTTPMethod,
          }
        : {
            path: `/api/v1/sections/${sectionId}/contents/courses`,
            method: "POST" as HTTPMethod,
          };

      const response = await ApiClient.sendFormData(path, method, params);

      if (response.ok) {
        const json = await response.json();
        if (!json.contentCourse) {
          // レスポンスは利用しないので,値が取れなくてもエラーにはしない
          return;
        }
        return fromJson(json.contentCourse);
      } else if (response.status === 422) {
        const errorResponse =
          (await response.json()) as ApiErrorResponseInterface;
        throw errorResponse;
      } else {
        throw [{ code: response.status }];
      }
    },
    {
      onSuccess: () => {
        if (courseId) {
          client.invalidateQueries(cacheKeyOf(sectionId, courseId));
        }
      },
    },
  );
};

type UseContentCourseDetalQueryProps = {
  sectionId: string;
  courseId: string | null;
  onQueryError: (error: HTTPErrors) => void;
  disableRefetchOnWindowFocus?: boolean;
};

export const useContentCourseDetailQuery = (
  props: UseContentCourseDetalQueryProps,
) => {
  const sectionId = props.sectionId;
  const courseId = props.courseId ?? "";
  const path = pathOfContentCourseDetailQuery(sectionId, courseId);

  const result = useQuery<ContentCourse, HTTPErrors>(
    cacheKeyOf(sectionId, courseId),
    async () => {
      const res = await ApiClient.get(`/api/v1${path}`);
      if (res.ok) {
        const json = await res.json();
        return fromJson(json.contentCourse);
      }
      throw await createError(res);
    },
    {
      retry: 0,
      onError: props.onQueryError,
      enabled: Boolean(props.courseId),
      refetchOnWindowFocus: !props.disableRefetchOnWindowFocus,
    },
  );
  return {
    isLoading: result.isLoading,
    course: result.data,
    error: result.error,
  };
};

export const pathOfContentCourseDetailQuery = (
  sectionId: string,
  courseId: string,
) => `/sections/${sectionId}/contents/courses/${courseId}`;

export const cacheKeyOf = (sectionId: string, courseId: string) => [
  pathOfContentCourseDetailQuery(sectionId, courseId),
];
