import { useMutation } from "@tanstack/react-query";
import api from "../../../api";
import { createError } from "../../../errors";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";

type MutateErrors = ApiErrorResponseInterface;
type Props = {
  onSuccess: () => void;
  onError: () => void;
};

export const useMutateRegister = ({ onSuccess, onError }: Props) => {
  return useMutation<void, MutateErrors, string>(
    async (email) => {
      const res = await api.post(`/api/v1/register`, {
        email_activation_token: { email },
      });

      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    { onSuccess, onError },
  );
};
