import classNames from "classnames";
import { Button } from "@studyplus/boron-ui";
import { Modal } from "../../../components/atoms/Modal";
import Radio from "../../../components/atoms/Radio";
import Textarea from "../../../components/atoms/Textarea";
import * as yup from "yup";
import { Text } from "../../../components/general/Text";
import styles from "./UpdateAttendanceModal.scss";
import {
  useModalStateContext,
  useModalDispatchContext,
  ModalDispatchers,
} from "./UpdateModalActionContext";
import { useBatchActionStateContext } from "./AttendBatchActionContext";
import { useMutateAttendance } from "./useMutateAttendance";
import { useSectionContext } from "../../../contexts/section";
import { usePageInfoContext } from "../PageInfoContext";
import Input from "../../../components/atoms/Input";
import { useFormik } from "formik";
import { LectureAttendanceForSection } from "../../../domains/LectureAttendance";
import ErrorText from "../../../components/atoms/ErrorText";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { ScreenReaderOnly } from "../../../components/general/ScreenReaderOnly";

// 共通部分の関心事
type UseUpdateAttendanceProps = {
  onError: () => void;
  onSuccess: () => void;
};
const useUpdateAttendance = ({
  onError,
  onSuccess,
}: UseUpdateAttendanceProps) => {
  const { targets: batchActionTargets } = useBatchActionStateContext();
  const { targetLectureAttendance } = useModalStateContext();
  const { closeModal } = useModalDispatchContext();
  const section = useSectionContext();
  const pageInfo = usePageInfoContext();
  const { mutate, isLoading: isMutating } = useMutateAttendance({
    onError,
    onSuccess,
    section,
    lectureSessionId: pageInfo.lectureSessionId,
  });
  // モーダル表示メソッドで直接渡されたlectureAttendanceがあれば、単発更新、無ければ一括更新
  const targets = targetLectureAttendance
    ? [targetLectureAttendance]
    : batchActionTargets;

  return {
    isMutating,
    mutate,
    closeModal,
    targets,
  };
};
export const UpdateAttendanceModal = () => {
  const { isOpen, updateType } = useModalStateContext();
  const { closeModal } = useModalDispatchContext();
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      {updateType === "forAttendance" && (
        <UpdateToAttendView onClose={closeModal} />
      )}
      {updateType === "forAbsence" && (
        <UpdateToAbsentView onClose={closeModal} />
      )}
      {updateType === "forStaffShareNote" && (
        <UpdateToStaffShareNoteView onClose={closeModal} />
      )}
    </Modal>
  );
};

type CloseProp = {
  onClose: ModalDispatchers["closeModal"];
};

type Values = {
  reason: LectureAttendanceForSection["absenceReason"];
  description: LectureAttendanceForSection["absenceDescription"];
};
const validationSchema = yup.object().shape({
  reason: yup.string().nullable().required("欠席理由を選択してください"),
});

type useAbsenceFormProps = {
  onSubmit: (formData: Values) => void;
};
const useAbsenceForm = ({ onSubmit }: useAbsenceFormProps) => {
  const formik = useFormik<Values>({
    initialValues: {
      reason: null,
      description: null,
    },
    validationSchema,
    onSubmit,
  });
  return formik;
};

const useUpdateToAbsentView = () => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();
  const { closeModal } = useModalDispatchContext();
  const { mutate, isMutating, targets } = useUpdateAttendance({
    onError() {
      showErrorMessage(
        "欠席の登録に失敗しました。しばらく間を置いてから再度お試しください。",
      );
    },
    onSuccess() {
      showSuccessMessage("欠席を登録しました");
      closeModal();
    },
  });
  const form = useAbsenceForm({
    onSubmit(values) {
      mutate({
        targets,
        action: {
          type: "forAbsence",
          param: {
            absenceReason: values.reason,
            absenceDescription: values.description,
          },
        },
      });
    },
  });

  return {
    isMutating,
    errors: form.errors,
    setFieldValue: form.setFieldValue,
    values: form.values,
    handleChange: form.handleChange,
    onSubmit: form.handleSubmit,
  };
};
const UpdateToAbsentView = ({ onClose }: CloseProp) => {
  const { errors, values, setFieldValue, handleChange, onSubmit, isMutating } =
    useUpdateToAbsentView();
  return (
    <>
      <Modal.Header onClose={onClose}>
        <h2>欠席に変更</h2>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className={styles.UpdateToAbsentView}>
            <div role="radiogroup" aria-labelledby="desc-radios">
              <span id="desc-radios" className={styles.ReadonRadioGroups__Desc}>
                <Text bold as="span">
                  欠席の理由
                </Text>
              </span>
              {errors.reason && <ErrorText>{errors.reason}</ErrorText>}
              <ul className={styles.ReadonRadioGroups__Radios}>
                <li>
                  <Radio
                    id="radi_bad_health"
                    label="体調不良"
                    checked={values.reason === "bad_health"}
                    onChange={() => setFieldValue("reason", "bad_health")}
                    value="bad_health"
                  />
                </li>
                <li>
                  <Radio
                    id="raido_personal"
                    label="本人都合"
                    checked={values.reason === "personal"}
                    onChange={() => setFieldValue("reason", "personal")}
                    value="personal"
                  />
                </li>
                <li>
                  <Radio
                    id="radio_family"
                    label="家庭都合"
                    checked={values.reason === "family"}
                    onChange={() => setFieldValue("reason", "family")}
                    value="family"
                  />
                </li>
                <li>
                  <Radio
                    id="radio_without_notice"
                    label="無断欠席"
                    checked={values.reason === "without_notice"}
                    onChange={() => setFieldValue("reason", "without_notice")}
                    value="without_notice"
                  />
                </li>
                <li>
                  <Radio
                    id="radio_other"
                    label="その他"
                    checked={values.reason === "other"}
                    onChange={() => setFieldValue("reason", "other")}
                    value="other"
                  />
                </li>
              </ul>
            </div>
            <div>
              <ScreenReaderOnly>
                <label htmlFor="absence_reason_description">
                  欠席理由の詳細
                </label>
              </ScreenReaderOnly>
              <Input
                id="absence_reason_description"
                placeholder="理由詳細"
                name="description"
                value={values.description ?? ""}
                onChange={handleChange}
              />
            </div>
            <div
              className={classNames(
                styles.ButtonAreaWrapper,
                styles["ButtonAreaWrapper--margin-top24"],
              )}
            >
              <Button
                variant="outline"
                disabled={isMutating}
                onClick={onClose}
                className={styles.button}
              >
                キャンセル
              </Button>
              <Button
                type="submit"
                disabled={isMutating}
                isLoading={isMutating}
                className={styles.button}
              >
                変更
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </>
  );
};

const useUpdateToAttendView = () => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { targets, closeModal, mutate, isMutating } = useUpdateAttendance({
    onError() {
      showErrorMessage(
        "出席の登録に失敗しました。しばらく間を置いてから再度お試しください。",
      );
    },
    onSuccess() {
      showSuccessMessage("出席を登録しました");
      closeModal();
    },
  });
  const onSubmit = () => {
    mutate({ targets, action: { type: "forAttendance" } });
  };

  return {
    isMutating,
    count: targets.length,
    closeModal,
    onSubmit,
  };
};
const UpdateToAttendView = ({ onClose }: CloseProp) => {
  const { count, closeModal, onSubmit, isMutating } = useUpdateToAttendView();

  return (
    <>
      <Modal.Header onClose={onClose}>
        <h2>出席に変更</h2>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.UpdateToAttendView}>
          <Text as="p" bold>
            {count}件の対象者の出欠状況を「出席」に変更します。
          </Text>
          <div className={styles.ButtonAreaWrapper}>
            <Button
              variant="outline"
              onClick={closeModal}
              disabled={isMutating}
              className={styles.button}
            >
              キャンセル
            </Button>
            <Button
              onClick={onSubmit}
              disabled={isMutating}
              isLoading={isMutating}
              className={styles.button}
            >
              変更
            </Button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

const shareNoteValidationSchema = yup.object().shape({
  note: yup
    .string()
    .trim()
    .max(100, "職員共有メモは100文字以内で入力してください"),
});
type StaffShareNoteValues = {
  note: LectureAttendanceForSection["staffShareNote"];
};
type useStaffShareNoteFormProps = {
  onSubmit: (formData: StaffShareNoteValues) => void;
};
const useStaffShareNoteForm = ({ onSubmit }: useStaffShareNoteFormProps) => {
  const { targetLectureAttendance } = useModalStateContext();
  const formik = useFormik<StaffShareNoteValues>({
    initialValues: {
      note: targetLectureAttendance?.staffShareNote ?? "",
    },
    validationSchema: shareNoteValidationSchema,
    onSubmit(value) {
      onSubmit(shareNoteValidationSchema.cast(value) as StaffShareNoteValues);
    },
  });
  return formik;
};

const useUpdateToStaffShareNoteView = () => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { mutate, closeModal, isMutating, targets } = useUpdateAttendance({
    onError() {
      showErrorMessage(
        "職員共有メモの登録に失敗しました。しばらく間を置いてから再度お試しください。",
      );
    },
    onSuccess() {
      showSuccessMessage("職員共有メモを登録しました");
      closeModal();
    },
  });
  const form = useStaffShareNoteForm({
    onSubmit(values) {
      mutate({
        targets,
        action: {
          type: "forStaffShareNote",
          param: {
            staffShareNote: values.note,
          },
        },
      });
    },
  });

  return {
    isMutating,
    errors: form.errors,
    values: form.values,
    touched: form.touched,
    handleChange: form.handleChange,
    handleBlur: form.handleBlur,
    closeModal,
    onSubmit: form.handleSubmit,
  };
};
const UpdateToStaffShareNoteView = ({ onClose }: CloseProp) => {
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    onSubmit,
    isMutating,
  } = useUpdateToStaffShareNoteView();

  return (
    <>
      <Modal.Header onClose={onClose}>
        <h2>職員共有メモ</h2>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className={styles.UpdateToStaffShareNoteView}>
            <ScreenReaderOnly>
              <label htmlFor="staff_share_note">職員共有のメモ欄</label>
            </ScreenReaderOnly>
            <div className={styles.StaffShareNoteTextarea}>
              <Textarea
                id="staff_share_note"
                placeholder="メモを記入"
                name="note"
                value={values.note ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={Boolean(touched.note && errors.note)}
              />
              {Boolean(touched.note && errors.note) && (
                <ErrorText>{errors.note}</ErrorText>
              )}
            </div>
            <Text as="p" size="xs">
              ※メモの内容はスタッフメンバーのみ閲覧可能です。生徒側には表示されません。
            </Text>
            <div className={styles.ButtonAreaWrapper}>
              <Button
                variant="outline"
                disabled={isMutating}
                onClick={onClose}
                className={styles.button}
              >
                キャンセル
              </Button>
              <Button
                type="submit"
                disabled={isMutating}
                isLoading={isMutating}
                className={styles.button}
              >
                登録
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </>
  );
};
