import api from "../../../../api";
import { Building } from "../../../../domains/Building";
import { useQuery } from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../../../errors";

type UseFetchBuildingProps = {
  sectionId: string;
};
export const useFetchBuildingsApi = ({ sectionId }: UseFetchBuildingProps) => {
  return useQuery<Building[], HTTPErrors>(cacheKeyOf(sectionId), async () => {
    const response = await api.get(`/api/v1/sections/${sectionId}/buildings`);
    if (response.ok) {
      const json = await response.json();
      return json.buildings as Building[];
    }
    throw await createError(response);
  });
};

export const cacheKeyOf = (sectionId: string) => [sectionId, "buildings"];
