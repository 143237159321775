import { useInfiniteQuery } from "@tanstack/react-query";
import { paths } from "../../lib/api/v1";
import { HTTPErrors, createError } from "../../errors";
import { boronClient } from "../../api";

type Props = {
  studentId: string;
};

type StudentTimelineResponse =
  paths["/api/v1/students/{student_id}/timeline"]["get"]["responses"]["200"]["content"]["application/json"];

const url = "/api/v1/students/{student_id}/timeline";

export const useFetchStudentTimeline = ({ studentId }: Props) => {
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    error,
    fetchNextPage,
  } = useInfiniteQuery<StudentTimelineResponse, HTTPErrors>({
    queryKey: [url, studentId],
    queryFn: ({ pageParam = undefined }) =>
      fetchStudentTimeline({ pageParam, studentId }),
    getNextPageParam: ({ studyRecords }) => studyRecords.meta.till,
    enabled: studentId !== "",
  });

  return {
    data: data?.pages.flatMap((page) => page.studyRecords.data),
    meta: data?.pages.flatMap((page) => page.studyRecords.meta)[0],
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
  };
};

const fetchStudentTimeline = async ({
  studentId,
  pageParam,
}: {
  studentId: string;
  pageParam?: string;
}) => {
  const { data, response } = await boronClient.GET(url, {
    params: {
      query: {
        since: pageParam,
      },
      path: {
        student_id: studentId,
      },
    },
  });

  if (response.ok && data) {
    return data;
  }

  throw await createError(response);
};
