import { useQuery } from "@tanstack/react-query";
import { paths } from "../../lib/api/v1";
import { boronClient } from "../../api";
import { createError, HTTPErrors } from "../../errors";

const path =
  "/api/v1/sections/multi_section_batch_histories/{multi_section_batch_history_id}";

type Payload = {
  datetimeFrom: string;
};

type ResponseBody =
  paths[typeof path]["get"]["responses"]["200"]["content"]["application/json"] & {
    multiSectionBatchHistory: {
      payload: Payload;
    };
  };

export type MultiSectionBatch = Exclude<
  ResponseBody["multiSectionBatchHistory"],
  undefined
>;

type Props = {
  batchId: string;
};

const shouldRefetch = (data: ResponseBody | undefined) => {
  if (data === undefined) return true;

  return ["standby", "processing"].includes(
    data.multiSectionBatchHistory.status,
  );
};

export const useMultiSectionBatchesDetail = ({ batchId }: Props) => {
  const { data, isLoading, error } = useQuery<ResponseBody, HTTPErrors>({
    queryKey: ["multiSectionBatchHistories", batchId],
    queryFn: async () => {
      const { response } = await boronClient.GET(path, {
        params: {
          path: {
            multi_section_batch_history_id: batchId,
          },
        },
      });

      if (response.ok) return response.json();

      throw await createError(response);
    },
    refetchInterval: (data) =>
      shouldRefetch(data)
        ? 1000 * 3 // 3秒ごとに再取得する
        : false, // 成功・失敗のときは再取得しない
    cacheTime: 0, // ログインユーザーを変更した時に再取得されるようにキャッシュしない
  });

  return {
    data,
    isLoading,
    error,
  };
};
