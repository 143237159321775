import * as React from "react";
import {
  useFormikWithClassroom,
  FormValue,
  parseLatitudeAndLongitude,
} from "./useFormikWithClassroom";
import {
  Classroom,
  maxCorrectionRange,
  minCorrectionRange,
} from "../../../../domains/Classroom";
import {
  ClassroomURLTypes,
  determineButtonLabel,
  ClassroomURLType,
} from "../useClassroomURLAction";
import { useSaveClassroom } from "../useClassroomApi";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { useNavigate } from "react-router";
import { OptionType } from "../../../../components/general/SelectWrapper";
import { isUnprocessableEntityError } from "../../../../errors";

const defaultOptions = () => {
  return [
    { label: "指定なし", value: null },
    { label: "半径100メートル以内", value: 100 },
    { label: "半径200メートル以内", value: 200 },
    { label: "半径300メートル以内", value: 300 }, // default
    { label: "半径400メートル以内", value: 400 },
    { label: "半径500メートル以内", value: 500 },
    { label: "半径800メートル以内", value: 800 },
    { label: "半径1000メートル以内", value: 1000 },
  ];
};

export type Props = {
  sectionId: string;
  editingClassroom: Classroom | undefined;
  urlActionType: ClassroomURLTypes;
};

export const useClassroomForm = ({
  urlActionType,
  editingClassroom,
  sectionId,
}: Props) => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();
  const actionLabel = determineButtonLabel(urlActionType);
  const navigate = useNavigate();

  const { mutate, isLoading: saving } = useSaveClassroom({
    sectionId,
    onError(error) {
      const message = isUnprocessableEntityError(error)
        ? error.getErrorMessages().join("\n")
        : `教室の${actionLabel}に失敗しました。しばらく間を置いてから再度お試しください`;
      showErrorMessage(message);
    },
    onSuccess() {
      showSuccessMessage(`教室の${actionLabel}に成功しました`);
      navigate(`/sections/${sectionId}/settings/classrooms`);
    },
  });

  const formikInstance = useFormikWithClassroom({
    initialValues: editingClassroom ?? null,
    onSubmit(value) {
      const [latitude, longitude] = parseLatitudeAndLongitude(
        value.latitudeAndLongitude,
      );
      const params = {
        name: value.name,
        latitude: latitude ?? null,
        longitude: longitude ?? null,
        correctionRange: value.correctionRange,
      };
      mutate({
        ...params,
        // 新規の場合はidをnull, 更新の場合はfetchしたデータのidを渡す
        id:
          urlActionType === ClassroomURLType.edit && editingClassroom
            ? editingClassroom.id
            : null,
      });
    },
  });

  // props
  const formProps = {
    onSubmit: formikInstance.handleSubmit,
  };
  const nameProps = makeFormFieldInputProps("name", formikInstance);
  const nameErrorProps = {
    hidden: !nameProps.hasError,
  };
  const nameErrorText = formikInstance.errors.name;

  const latitudeAndLongitudeProps = makeFormFieldInputProps(
    "latitudeAndLongitude",
    formikInstance,
  );
  const latitudeAndLongitudeErrorProps = {
    hidden: !latitudeAndLongitudeProps.hasError,
  };
  const latitudeAndLongitudeErrorText =
    formikInstance.errors.latitudeAndLongitude;

  const optionsWithCustom = React.useMemo(() => {
    const options = defaultOptions();
    const correctionRange = editingClassroom?.correctionRange;
    if (!correctionRange || options.find((o) => o.value == correctionRange)) {
      return options;
    }
    const insertAt = options.findIndex(
      (o) => o.value && o.value > correctionRange,
    );
    const customOption = {
      label: `半径${correctionRange}メートル以内`,
      value: correctionRange,
    };
    options.splice(insertAt, 0, customOption);
    return options;
  }, [editingClassroom]);

  const correctionRangeProps = {
    options: optionsWithCustom,
    min: minCorrectionRange,
    max: maxCorrectionRange,
    ...makeCorrectionRangeFormProps(formikInstance, optionsWithCustom),
    onChange: (value: OptionType | null | unknown) => {
      formikInstance.setFieldValue("correctionRange", value);
    },
  };
  const correctionRangeErrorProps = {
    hidden: !correctionRangeProps.hasError,
  };
  const correctionRangeErrorText = formikInstance.errors.correctionRange;

  const buttonProps = {
    isLoading: saving,
    disabled: saving,
  };

  return {
    formProps,
    nameProps,
    nameErrorProps,
    nameErrorText,
    latitudeAndLongitudeProps,
    latitudeAndLongitudeErrorProps,
    latitudeAndLongitudeErrorText,
    correctionRangeProps,
    correctionRangeErrorProps,
    correctionRangeErrorText,
    buttonProps,
    buttonLabel: actionLabel,
  };
};

const makeFormFieldInputProps = (
  field: keyof FormValue,
  formikInstance: ReturnType<typeof useFormikWithClassroom>,
) => {
  return {
    name: field,
    value: formikInstance.values[field] ?? "",
    hasError:
      Boolean(formikInstance.touched[field]) &&
      Boolean(formikInstance.errors[field]),
    onChange: formikInstance.handleChange,
    onBlur: formikInstance.handleBlur,
  };
};

const makeCorrectionRangeFormProps = (
  formikInstance: ReturnType<typeof useFormikWithClassroom>,
  options: Array<OptionType>,
) => {
  const field = "correctionRange";
  const props = makeFormFieldInputProps(field, formikInstance);
  return {
    ...props,
    value:
      options.find((o) => o.value == props.value) ??
      options.find((o) => o.label == "指定なし"),
  };
};
