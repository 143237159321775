import { boronClient } from "../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { HTTP_ERROR_MESSAGE } from "../../reducers";

type UseDeleteStudyTaskHook = {
  onDelete: () => void;
  isDeleting: boolean;
};

export const useDeleteStudyTask = ({
  sectionId,
  studyTaskId,
}: {
  sectionId: string;
  studyTaskId: string;
}): UseDeleteStudyTaskHook => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const client = useQueryClient();
  const { mutate, isLoading: isDeleting } = useMutation(
    async () => {
      if (confirm("課題を削除します。よろしいですか？")) {
        const { response } = await boronClient.DELETE(
          "/api/v1/sections/{section_id}/study_tasks/{study_task_hashid}",
          {
            params: {
              path: {
                section_id: sectionId,
                study_task_hashid: studyTaskId,
              },
            },
          },
        );

        if (response.ok) {
          showSuccessMessage("成功しました");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      }
    },
    {
      onSuccess: () =>
        client.invalidateQueries([
          "/api/v1/sections/{section_id}/analytics/study_tasks",
        ]),
    },
  );

  return {
    onDelete: () => mutate(),
    isDeleting,
  };
};
