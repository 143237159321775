import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import api from "../../../../api";
import { GuardianEmail } from "../../../../domains/GuardianEmail";
import { createError, HTTPErrors } from "../../../../errors";

type FetchEmailsProps = {
  studentId: string;
  enabled: boolean;
};
export const useFetchGuardianEmails = ({
  studentId,
  enabled,
}: FetchEmailsProps) => {
  return useQuery<GuardianEmail[], HTTPErrors>(
    cacheKeyOf(studentId),
    async () => {
      const response = await api.interruptGet(pathOf(studentId));
      if (response.ok) {
        const json = await response.json();
        return json.guardianEmails as GuardianEmail[];
      }
      throw await createError(response);
    },
    {
      enabled,
    },
  );
};

type MutateEmailProps = { studentId: string };
type MutateEmailParams = { email: string };
export const useMutateGuardianEmails = ({ studentId }: MutateEmailProps) => {
  const path = pathOf(studentId);
  const client = useQueryClient();

  return useMutation<void, HTTPErrors, MutateEmailParams>(
    async (params) => {
      const result = await api.post(path, params);

      if (result.ok) {
        return;
      }
      throw await createError(result);
    },
    {
      onSuccess() {
        client.invalidateQueries(cacheKeyOf(studentId));
      },
    },
  );
};

type DeleteEmailProps = { studentId: string; guardianEmailId: string };
export const useDeleteGuardianEmail = ({
  studentId,
  guardianEmailId,
}: DeleteEmailProps) => {
  const client = useQueryClient();

  return useMutation<void, HTTPErrors>(
    async () => {
      const result = await api.delete(
        `/api/v1/students/${studentId}/guardian_emails/${guardianEmailId}`,
      );

      if (result.ok) {
        return;
      }
      throw await createError(result);
    },
    {
      onSuccess() {
        client.invalidateQueries(cacheKeyOf(studentId));
      },
    },
  );
};

type ResendConfirmationEmailProps = {
  studentId: string;
  guardianEmailId: string;
};
export const useResendConfirmationEmail = ({
  studentId,
  guardianEmailId,
}: ResendConfirmationEmailProps) => {
  const path = `/api/v1/students/${studentId}/guardian_emails/${guardianEmailId}/send_email`;
  const client = useQueryClient();

  return useMutation<void, HTTPErrors, void>(
    async () => {
      const result = await api.post(path);

      if (result.ok) {
        return;
      }
      throw await createError(result);
    },
    {
      onSuccess() {
        client.invalidateQueries(cacheKeyOf(studentId));
      },
    },
  );
};

const cacheKeyOf = (studentId: string) => {
  return ["guardian_emails", studentId];
};

const pathOf = (studentId: string) => {
  return `/api/v1/students/${studentId}/guardian_emails`;
};
