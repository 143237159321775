import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../../api";
import { LectureAttendanceWithLectureAnswers } from "../../../domains/LectureAttendance";
import { HTTPErrors, createError } from "../../../errors";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import ApiResponse from "../../../interfaces/ApiResponse";
import SectionInterface from "../../../interfaces/SectionInterface";
import { toLectureAttedanceWithLectureAnswersFromJson } from "../../../domain-adapters/LectureAttendance";

type ResponseData = ApiResponse<LectureAttendanceWithLectureAnswers[]>;

type Props = {
  section: SectionInterface;
  lectureSessionId: string;
};

export const useFetchLectureAnswers = ({
  section,
  lectureSessionId,
}: Props) => {
  const result = useInfiniteQuery<ResponseData, HTTPErrors>(
    cacheKeyOf(section, lectureSessionId),
    async ({ pageParam = 1 }) => {
      const response = await api.get(urlOf(section, lectureSessionId), {
        query: { page: pageParam },
      });
      if (response.ok) {
        const json = await response.json();
        return {
          meta: json.lectureAttendances.meta,
          data: json.lectureAttendances.data.map(
            (lectureAttendance: Record<string, unknown>) =>
              toLectureAttedanceWithLectureAnswersFromJson(lectureAttendance),
          ),
        };
      }
      throw await createError(response);
    },
    {
      getNextPageParam,
    },
  );
  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data };
};

const cacheKeyOf = (section: SectionInterface, lectureSessionId: string) => [
  "lectureAnswers",
  section.id,
  lectureSessionId,
];

const urlOf = (section: SectionInterface, lectureSessionId: string) =>
  `/api/v1/sections/${section.id}/lecture_sessions/${lectureSessionId}/lecture_answers`;
