import * as React from "react";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useLazyFetchContent } from "./useContentVideoApi";

type Status =
  | "Init" // 初期値
  | "LoadingContent" // 指定されたIDのコンテンツをリクエスト中
  | "404ContentError" // 指定されたIDが存在しない時
  | "403ContentError" // 指定されたIDが許可されていないリソースの時
  | "FetchContentError" // 上記２つ以外のなんかのエラーがリクエスト中起こった時
  | "ReadyUserInput"; // ユーザーが入力してもいい状態、新規登録はすぐにこの状態になる、編集はコンテンツを取得してから

type UsePrepareUserInputProps = {
  section: SectionInterface;
  contentId: string | null;
};
export const usePrepareUserInput = ({
  section,
  contentId,
}: UsePrepareUserInputProps) => {
  const [status, setStatus] = React.useState<Status>("Init");

  const { data, execQuery } = useLazyFetchContent({
    section,
    contentId,
    onSuccess: () => {
      setStatus("ReadyUserInput");
    },
    onError: (error) => {
      if (error === "404Error") {
        setStatus("404ContentError");
      } else if (error === "403Error") {
        setStatus("403ContentError");
      } else {
        setStatus("FetchContentError");
      }
    },
  });

  React.useEffect(() => {
    if (contentId) {
      setStatus("LoadingContent");
      execQuery();
    } else {
      setStatus("ReadyUserInput");
    }
  }, [contentId]);

  return {
    status,
    data,
  };
};
