import {
  useMutation,
  UseMutateFunction,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  toJson,
  Schedule,
  UpdateType,
  ScheduleForStudent,
} from "../../../domains/Schedule";
import { toStudentScheduleFromJson } from "../../../domain-adapters/Schedule";
import ApiClient from "../../../api";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import { HTTP_ERROR_MESSAGE } from "../../../reducers/index";
import { toDateString } from "../../../helpers/TimeHelper";
import { useCallback, useMemo } from "react";
import { createError, HTTPErrors } from "../../../errors";

export type FetchSchedulesParams = {
  studentId: string;
  from: Date;
  to: Date;
};

export type FetchSchedulesHooks = {
  isError: boolean;
  isLoading: boolean;
  data: ScheduleForStudent[] | undefined;
  error: HTTPErrors | null;
};

type CacheKeys = {
  studentId: string;
  from: string;
  to: string;
};

const buildCacheKeys = ({ studentId, from, to }: CacheKeys): string[] => [
  `students/${studentId}/schedules`,
  from,
  to,
];

export const useFetchSchedules = (
  params: FetchSchedulesParams,
): FetchSchedulesHooks => {
  const { studentId } = params;
  const from = toDateString(params.from);
  const to = toDateString(params.to);

  const { isLoading, data, isError, error } = useQuery<
    ScheduleForStudent[] | undefined,
    HTTPErrors
  >(
    buildCacheKeys({ studentId, from, to }),
    async () => {
      const res = await ApiClient.get(
        `/api/v1/students/${studentId}/schedules`,
        {
          query: {
            from,
            to,
          },
        },
      );

      if (res.ok) {
        const json = await res.json();
        if (!Array.isArray(json.schedules)) {
          console.error("The response must return an array of schedule");
          throw await createError(res);
        }
        return json.schedules.map((schedule: Record<string, any>) =>
          toStudentScheduleFromJson(schedule),
        );
      } else {
        throw await createError(res);
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { isLoading, data, isError, error };
};

export type MutateScheduleProps = {
  studentId: string;
  from: Date;
  to: Date;
  refetchOnSettled?: boolean;
};

type MutateScheduleParams = {
  schedule: Schedule;
  updateType?: UpdateType;
};

type MutateScheduleApiHooks = {
  isLoading: boolean;
  isError: boolean;
  data: Schedule | undefined;
  error: string | null;
  mutate: UseMutateFunction<Schedule, string, MutateScheduleParams>;
};

const useRequest = (studentId: string) => {
  return useCallback(
    async ({ updateType, schedule }: MutateScheduleParams) => {
      // schedule.idがすでにあるものは更新API、なければ新規登録のAPIを叩く
      const res = schedule.id
        ? await ApiClient.patch(
            `/api/v1/students/${studentId}/schedules/${schedule.id}`,
            {
              ...toJson(schedule),
              update_type: updateType,
            },
          )
        : await ApiClient.post(
            `/api/v1/students/${studentId}/schedules`,
            toJson(schedule),
          );
      if (res.ok) {
        const json = await res.json();
        return toStudentScheduleFromJson(json.schedule);
      }

      if (res.status === 422) {
        const errorResponse = (await res.json()) as ApiErrorResponseInterface;
        throw errorResponse.errors.map((e) => e.message).join("\n");
      } else {
        throw HTTP_ERROR_MESSAGE;
      }
    },
    [studentId],
  );
};

export const useMutateSchedule = (
  props: MutateScheduleProps,
): MutateScheduleApiHooks => {
  const { studentId, from, to } = props;
  const client = useQueryClient();
  const request = useRequest(studentId);

  const cacheKeys = useMemo(
    () =>
      buildCacheKeys({
        studentId,
        from: toDateString(from),
        to: toDateString(to),
      }),

    [from, to, studentId],
  );

  const { mutate, isLoading, isError, data, error } = useMutation<
    Schedule,
    string,
    MutateScheduleParams
  >(request, {
    onSettled: () => {
      if (props.refetchOnSettled) {
        client.invalidateQueries(cacheKeys);
      }
    },
    onMutate: () => {
      const oldSchedules = client.getQueryData(cacheKeys);
      return () => client.setQueryData(cacheKeys, oldSchedules);
    },
    onError: (_o, _p, rollback) => {
      // 更新が失敗した場合は古いデータでロールバックする
      // 登録でも走っちゃうけど、害はないので一旦このまま。必要あればoptionにして外に出す。
      if (typeof rollback === "function") {
        rollback();
      }
    },
  });

  return {
    isLoading,
    isError,
    data,
    error,
    mutate,
  };
};

export const useMutateScheduleFromStudyScheduleForm = (props: {
  studentId: string;
}): MutateScheduleApiHooks => {
  const { studentId } = props;
  const request = useRequest(studentId);

  const { mutate, isLoading, isError, data, error } = useMutation<
    Schedule,
    string,
    MutateScheduleParams
  >(request, {});

  return {
    isLoading,
    isError,
    data,
    error,
    mutate,
  };
};

type UseMutateScheduleDragAndDropProps = Pick<
  MutateScheduleProps,
  "studentId" | "from" | "to"
> & {
  onSuccess: (
    data: Schedule,
    param: MutateScheduleDragAndDropParams,
    context?: MutateScheduleDragAndDropContext,
  ) => void;
  onError: () => void;
};
type MutateScheduleDragAndDropParams = MutateScheduleParams & {
  originalSchedule: Schedule;
};
type MutateScheduleDragAndDropContext = {
  originalSchedule: Schedule;
  requestedSchedule: Schedule;
};

/*
 * 下記の事情で useMutateScheduleとは別のメソッドにし、リクエスト部分を共通化した
 * - Context の型が違う
 * - mutate の引数の型が違う
 *   (コールバックで編集APIが返した予定IDと編集前の予定IDを、Contextに保持するため、idを上書きする前のScheduleを受け取っている)
 */
export const useMutateScheduleDragAndDrop = (
  props: UseMutateScheduleDragAndDropProps,
) => {
  const { studentId } = props;
  const request = useRequest(studentId);

  const { mutate, isLoading, isError, data, error, context } = useMutation<
    Schedule,
    string,
    MutateScheduleDragAndDropParams,
    MutateScheduleDragAndDropContext
  >(request, {
    onMutate: (params) => {
      return {
        requestedSchedule: params.schedule,
        originalSchedule: params.originalSchedule,
      };
    },
    onSuccess: (data, v, context) => {
      if (props.onSuccess) {
        props.onSuccess(data, v, context);
      }
    },
    onError: props.onError,
  });

  return {
    isLoading,
    isError,
    data,
    error,
    mutate,
    context,
  };
};

export const useRollback = ({ studentId, from, to }: MutateScheduleProps) => {
  const client = useQueryClient();
  const key = useMemo(
    () =>
      buildCacheKeys({
        studentId,
        from: toDateString(from),
        to: toDateString(to),
      }),
    [studentId, from, to],
  );
  return () => {
    // TODO: オフラインでもロールバックできる方法
    client.invalidateQueries(key);
  };
};

type DeleteScheduleParams = {
  schedule: Schedule;
  updateType: UpdateType;
};
type DeleteScheduleApiHooks = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: string | null;
  mutate: UseMutateFunction<number, string, DeleteScheduleParams>;
  data?: number;
};
export const useDeleteSchedule = (
  props: MutateScheduleProps,
): DeleteScheduleApiHooks => {
  const { studentId, from, to } = props;
  const client = useQueryClient();
  const request = useCallback(
    async ({ updateType, schedule }: DeleteScheduleParams) => {
      return await ApiClient.delete(
        `/api/v1/students/${studentId}/schedules/${schedule.id}`,
        {
          update_type: updateType,
        },
      );
    },
    [studentId],
  );

  const cacheKeys = useMemo(
    () =>
      buildCacheKeys({
        studentId,
        from: toDateString(from),
        to: toDateString(to),
      }),

    [from, to, studentId],
  );

  const { mutate, isLoading, isError, error, isSuccess, data } = useMutation<
    number,
    string,
    DeleteScheduleParams
  >(
    async (params: DeleteScheduleParams) => {
      const res = await request(params);

      if (res.ok) {
        return res.status;
      } else if (res.status === 422) {
        const errorResponse = (await res.json()) as { message: string };
        throw new Error(errorResponse.message);
      } else {
        throw HTTP_ERROR_MESSAGE;
      }
    },
    {
      onSuccess: () => {
        client.invalidateQueries(cacheKeys);
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    error,
    mutate,
    data,
  };
};
