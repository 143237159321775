import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../../../api";
import { Classroom } from "../../../domains/Classroom";
import { createError, HTTPErrors } from "../../../errors";

type UseFetchClassroom = {
  sectionId: string;
  classroomId: string | null;
};
export const useFetchClassroomWithId = ({
  sectionId,
  classroomId,
}: UseFetchClassroom) => {
  // "" になるケースは新規登録時なので、実際にはリクエストされない
  const baseURL = classroomId ? cahceKeyOf(sectionId, classroomId) : "";
  return useQuery<Classroom, HTTPErrors>(
    [baseURL],
    async () => {
      const res = await api.interruptGet(baseURL);
      if (res.ok) {
        const json = await res.json();
        return json.classroom as Classroom;
      }
      throw await createError(res);
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: Boolean(classroomId),
    },
  );
};

type SaveCallbacks = {
  onSuccess: () => void;
  onError: (errors: HTTPErrors) => void;
};
type SaveClassroomParams = Omit<Classroom, "id"> & { id: string | null };
type UseSaveClassroomProps = { sectionId: string } & SaveCallbacks;

export const useSaveClassroom = ({
  sectionId,
  onError,
  onSuccess,
}: UseSaveClassroomProps) => {
  const baseURL = `/api/v1/sections/${sectionId}/classrooms`;
  return useMutation<void, HTTPErrors, SaveClassroomParams>(
    async (params) => {
      const commonParams = {
        name: params.name,
        latitude: params.latitude,
        longitude: params.longitude,
        correction_range: params.correctionRange,
      };
      const res = params.id
        ? await api.patch(`${baseURL}/${params.id}`, {
            classroom: {
              id: params.id,
              ...commonParams,
            },
          })
        : await api.post(baseURL, { classroom: commonParams });
      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    {
      onSuccess,
      onError,
    },
  );
};

export const cahceKeyOf = (sectionId: string, classroomId: string) =>
  `/api/v1/sections/${sectionId}/classrooms/${classroomId}`;
