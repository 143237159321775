import { useMemo, useCallback } from "react";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import api from "../../../api";
import StudentInterface from "../../../interfaces/StudentInterface";
import { toQueryString } from "../../../helpers/TimeHelper";
import {
  LearningMaterialTagList,
  LearningMaterialTagStudyTimeSummary,
} from "../../../domains/LearningMaterialTag";
import { AnalyticsTermType } from "../../../interfaces/AnalyticsTableInterface";
import { LearningMaterialTagAnalyticsHeatMap } from "../../../interfaces/StudentAnalyticsTableInterface";
import ApiResponse from "../../../interfaces/ApiResponse";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";
import { HTTPErrors, createError, NotFoundError } from "../../../errors";

export type UseLearningMaterialTagStudyTimeSummaryProps = {
  startDate: Date;
  term: AnalyticsTermType;
  learningMaterialTags: LearningMaterialTagList;
  student: StudentInterface;
  orderDir: OrderDirFilterType;
  order: string;
  enabledRequest: boolean;
};

export const useLearningMaterialTagStudyTimeSummary = ({
  student,
  startDate,
  term,
  orderDir,
  order,
  learningMaterialTags,
  enabledRequest,
}: UseLearningMaterialTagStudyTimeSummaryProps) => {
  const baseUrl = baseUrlOfSummary(student);
  const reqTagIds = learningMaterialTags.map((item) => item.id);
  const result = useQuery<LearningMaterialTagStudyTimeSummary, HTTPErrors>(
    [
      baseUrl,
      startDate.toISOString(),
      term,
      order,
      orderDir,
      reqTagIds.join(","),
    ],
    async () => {
      const res = await api.interruptGet(`${baseUrl}`, {
        query: {
          initial_date: toQueryString(startDate),
          term,
          order_dir: orderDir,
          order,
          tag_ids: reqTagIds,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return json.tagTimeAnalyticsSummary as LearningMaterialTagStudyTimeSummary;
      }
      throw createError(res);
    },
    {
      // canvas が再描画してしまうのを防ぐ
      refetchOnWindowFocus: false,
      retry: retryHandler,
      cacheTime: 0,
      enabled: enabledRequest,
    },
  );
  return result;
};

export const useRefreshAnalyticsApi = (student: StudentInterface) => {
  const client = useQueryClient();
  const execute = useCallback(() => {
    client.resetQueries([baseUrlOfSummary(student)]);
    client.resetQueries([baseUrlOfStudyScores(student)]);
  }, []);
  return { execute };
};

const baseUrlOfSummary = (student: StudentInterface) =>
  `/api/v1/students/${student.id}/analytics/tag_time/summary`;

type LearningMaterialTagStudyScoreResponse =
  ApiResponse<LearningMaterialTagAnalyticsHeatMap>;
type UseLearningMaterialTagStudyScoresProps =
  UseLearningMaterialTagStudyTimeSummaryProps & {
    learningMaterialTags: LearningMaterialTagList;
  };

export const useLearningMaterialTagStudyScores = ({
  student,
  startDate,
  term,
  learningMaterialTags,
  order,
  orderDir,
  enabledRequest,
}: UseLearningMaterialTagStudyScoresProps) => {
  const baseUrl = baseUrlOfStudyScores(student);
  const tagIds = learningMaterialTags.map((lmtag) => lmtag.id);

  const result = useInfiniteQuery<
    LearningMaterialTagStudyScoreResponse,
    HTTPErrors
  >(
    [baseUrl, startDate.toISOString(), term, tagIds.join(","), order, orderDir],
    async ({ pageParam = 1 }) => {
      const res = await api.interruptGet(baseUrl, {
        query: {
          tag_ids: tagIds,
          initial_date: toQueryString(startDate),
          term,
          page: pageParam,
          order,
          order_dir: orderDir,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return json.tagTimeAnalyticsTable as LearningMaterialTagStudyScoreResponse;
      }
      throw await createError(res);
    },
    {
      getNextPageParam,
      retry: retryHandler,
      enabled: enabledRequest,
    },
  );

  const data = useMemo(() => {
    const learningMaterialTagScores =
      result.data?.pages.flatMap((page) => page.data.learningMaterialTags) ??
      [];
    return result.data
      ? {
          ...result.data.pages[0].data,
          learningMaterialTags: learningMaterialTagScores,
        }
      : null;
  }, [result.data]);

  return { ...result, data };
};

const baseUrlOfStudyScores = (student: StudentInterface) =>
  `/api/v1/students/${student.id}/analytics/tag_time`;

const retryHandler = (failureCount: number, error: HTTPErrors) =>
  !(error instanceof NotFoundError) && failureCount < 3;
