export interface SectionsSettingsImportOperationStateInterface {
  submitting: boolean;
  errors: Array<{ [key: string]: string[] }>;
}

const defaultState: SectionsSettingsImportOperationStateInterface = {
  submitting: false,
  errors: [],
};

const sectionSettingsImportOperation = (
  state: SectionsSettingsImportOperationStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "POST_IMPORT_OPERATION_SENDING": {
      return {
        submitting: true,
        errors: [],
      };
    }
    case "POST_IMPORT_OPERATION_RECEIVED": {
      return {
        submitting: false,
        errors: [],
      };
    }
    case "POST_IMPORT_OPERATION_ERROR": {
      return {
        submitting: false,
        errors: action.payload ? action.payload.errors || [] : [],
      };
    }
    default:
      return state;
  }
};

export default sectionSettingsImportOperation;
