import { useInfiniteQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import { createError, HTTPErrors } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";
import { TimelineExaminationResult } from "../../domains/ExaminationResult";
import { StudentTagFilterInterface } from "../../domains/StudentTag";
import { buildStudentFilterParams } from "../../helpers/FiltersHelper";

type Response = ApiResponse<TimelineExaminationResult[]>;

type Props = {
  sectionId: string;
  studentFilter?: StudentTagFilterInterface;
};

const compact = <T extends Record<string, any>>(object: T): Partial<T> => {
  return Object.fromEntries(
    Object.entries(object).filter(([, value]) => value != null),
  ) as Partial<T>;
};

export const useFetchSectionTimelineExamination = ({
  sectionId,
  studentFilter,
}: Props) => {
  const params = studentFilter ? buildStudentFilterParams(studentFilter) : {};
  const { data, ...result } = useInfiniteQuery<Response, HTTPErrors>(
    cacheKeyOf(sectionId, params),
    async ({ pageParam = 1 }) => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/timeline/examination_results`,
        {
          query: {
            page: pageParam,
            ...compact(params),
          },
        },
      );
      if (response.ok) {
        const json = await response.json();
        return json.examinationResults;
      }
      throw await createError(response);
    },
    {
      getNextPageParam,
    },
  );

  return {
    data: data?.pages.flatMap((page) => page.data),
    ...result,
  };
};

const cacheKeyOf = (
  sectionId: string,
  params: ReturnType<typeof buildStudentFilterParams>,
) => [`/sections/${sectionId}/timeline/examination_results`, params];
