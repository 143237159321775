import * as React from "react";
import { ImportHistoryInterface } from "../../../domains/ImportHistory";
import {
  IconImportBookshelf,
  IconImportMaterial,
  IconImportSeiseki,
  IconImportStudent,
  IconImportStudenttag,
  IconImportTag,
  IconSetupDocument,
} from "@studyplus/boron-ui";

export const IconImportOperation: React.FC<{
  className: string;
  operation: ImportHistoryInterface["operation"];
}> = ({ className, operation }) => {
  switch (operation) {
    case "bookshelf_insert":
      return <IconImportBookshelf className={className} />;
    case "learning_material_insert":
    case "learning_material_update":
    case "drill_learning_material_insert":
      return <IconImportMaterial className={className} />;
    case "student_insert":
    case "student_update":
    case "student_billing_plan_update":
      return <IconImportStudent className={className} />;
    case "student_tag_insert":
    case "student_tag_delete":
      return <IconImportStudenttag className={className} />;
    case "tag_insert":
    case "tag_update":
      return <IconImportTag className={className} />;
    case "examination_result_insert":
      return <IconImportSeiseki className={`${className}`} />;
    case "entry_guides_for_students":
      return <IconSetupDocument className={`${className}`} />;
    case "entry_guides_for_guardians":
      return <IconSetupDocument className={`${className}`} />;
    default:
      return null;
  }
};
