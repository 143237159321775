import ApiClient from "../../../../api";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ERROR_MESSAGE } from "../../../../reducers/index";

type UseDeleteContentCourseTagHook = {
  isDeleting: boolean;
  onDelete: () => void;
};
export const useDeleteContentCourseTag = (
  sectionId: string,
  courseId: string,
): UseDeleteContentCourseTagHook => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const client = useQueryClient();
  const { mutate, isLoading: isDeleting } = useMutation(
    async (courseId: string) => {
      const res = await ApiClient.patch(
        `/api/v1/sections/${sectionId}/contents/courses/${courseId}/tag`,
        { tag_id: null },
      );
      if (res.ok) {
        showSuccessMessage("配信対象タグを削除しました。");
      } else {
        showErrorMessage(HTTP_ERROR_MESSAGE);
      }
    },
    {
      onSuccess: () => {
        client.invalidateQueries([
          `/sections/${sectionId}/contents/courses/${courseId}`,
          `/sections/${sectionId}/contents/courses/${courseId}/students`,
        ]);
      },
    },
  );

  const onDelete = useCallback(() => {
    if (
      confirm(
        `配信対象タグを削除してもよろしいですか？削除すると、生徒へ送信したコースURLが無効になります。`,
      )
    ) {
      mutate(courseId);
    }
  }, [mutate]);

  return {
    isDeleting,
    onDelete,
  };
};
