import * as React from "react";
import styles from "./LearningMaterialTagForm.scss";
import Label from "../../../components/atoms/Label";
import ErrorText from "../../../components/atoms/ErrorText";
import Input from "../../../components/atoms/Input";
import { Button } from "@studyplus/boron-ui";
import { useFormik } from "formik";
import { FormValue } from "./useLearningMaterialTagApi";
import * as yup from "yup";
import { LearningMaterialTag } from "../../../domains/LearningMaterialTag";
import { useSaveLearningMaterialTag } from "./useLearningMaterialTagApi";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { HTTPErrors, UnprocessableEntityError } from "../../../errors";

// Main hooks
type UseLearningMaterialTagFormProps = {
  section: SectionInterface;
  learningMaterialTag: LearningMaterialTag | null;
  onSuccessRegister: () => void;
};
export const useLearningMaterialTagForm = ({
  section,
  learningMaterialTag,
  onSuccessRegister,
}: UseLearningMaterialTagFormProps) => {
  const flashMessage = useFlashMessage();

  const {
    mutate,
    error,
    isLoading: isSaving,
  } = useSaveLearningMaterialTag({
    section,
    onSuccess() {
      flashMessage.showSuccessMessage("教材タグを登録しました");
      onSuccessRegister();
    },
    onError() {
      flashMessage.showErrorMessage("教材タグの登録に失敗しました");
    },
  });

  const form = useFormik<FormValue>({
    initialValues: learningMaterialTag ?? {
      name: "",
    },
    onSubmit(value) {
      mutate(value);
    },
    validationSchema: learningMaterialTagSchema,
    validateOnMount: true,
  });

  const formError = useFormErrorMessage({
    nameValue: form.values.name,
    nameError: form.errors.name,
    mutationError: getDuplicateErrorMessage(error), // TODO: エラー結合
  });

  const errorTextProps = {
    hidden: !(form.touched.name && formError.hasError),
  };

  const submitButtonProps = {
    disabled: formError.hasError,
    isLoading: isSaving,
  };

  const textBoxProps = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      form.setTouched({ ...form.touched, name: true });
      form.handleChange(e);
    },
  };

  return {
    form,
    submitButtonProps,
    errorTextProps,
    textBoxProps,
    errorMessage: formError.errorMessage,
  };
};

const learningMaterialTagSchema = yup.object({
  name: yup
    .string()
    .required("教材タグ名を入力してください")
    .max(32, "教材タグ名は32文字以内で入力してください"),
});

// Main Component
type Props = UseLearningMaterialTagFormProps;
export const LearningMaterialTagForm = (props: Props) => {
  const {
    form,
    errorTextProps,
    errorMessage,
    submitButtonProps,
    textBoxProps,
  } = useLearningMaterialTagForm(props);

  return (
    <form onSubmit={form.handleSubmit}>
      <div className={styles.label}>
        <Label htmlFor="learning-material-tag-name" isMute>
          教材タグ名
        </Label>
        {!errorTextProps.hidden && <ErrorText>{errorMessage}</ErrorText>}
      </div>
      <div>
        <Input
          id="learning-material-tag-name"
          aria-label="教材タグ名"
          name="name"
          value={form.values.name}
          onBlur={form.handleBlur}
          {...textBoxProps}
        />
      </div>
      <div className={styles.button}>
        <Button
          type="submit"
          className={styles.submitButton}
          {...submitButtonProps}
        >
          登録
        </Button>
      </div>
    </form>
  );
};

// Helpers
type UseFormErrorMessageProps = {
  nameValue: string;
  nameError?: string;
  mutationError: string | null;
};
const useFormErrorMessage = ({
  nameValue,
  nameError,
  mutationError,
}: UseFormErrorMessageProps) => {
  const [mutationErrorHolder, setMutationErrorHolder] = React.useState<
    string | null
  >(null);

  React.useEffect(() => {
    setMutationErrorHolder(null);
  }, [nameValue]);

  React.useEffect(() => {
    if (mutationError) {
      setMutationErrorHolder(mutationError);
    }
  }, [mutationError]);

  return {
    hasError: Boolean(nameError || mutationErrorHolder),
    errorMessage: nameError ?? mutationError ?? "",
  };
};

export const getDuplicateErrorMessage = (error: HTTPErrors | null) => {
  if (!error) {
    return null;
  }
  // learning_material_tag で複数のメッセージで422エラーを返してくることは実質なさそうなのでメッセージのインデックスを決め打ちで
  if (error instanceof UnprocessableEntityError && error.errorTable.name) {
    return error.errorTable.name;
  }
  return null;
};
