import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("スタッフ名は必須です")
    .max(32, "スタッフ名は32文字以下でご入力ください"),
  comment: yup.string().trim().required("コメントを入力してください"),
});

export default validationSchema;
