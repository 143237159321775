import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../api";
import { ArchiveStatus } from "../../domains/Attendance";
import { teachersCacheKeyOf } from "./useFetchTeachers";

type Props = { sectionId: string; status: ArchiveStatus };

export const useMutateTeacherArchiveStatus = ({ sectionId, status }: Props) => {
  const client = useQueryClient();
  const mutation = useMutation<void, unknown, string>(
    async (teacherId: string) => {
      const apiPath = `/api/v1/sections/${sectionId}/teachers/${teacherId}`;
      const response = await ApiClient.patch(apiPath, { status });

      if (response.ok) {
        return;
      } else {
        throw response.status;
      }
    },
    {
      onSuccess: () => {
        const statuses: ArchiveStatus[] = ["active", "archived"];
        statuses.forEach((status: ArchiveStatus) => {
          client.invalidateQueries(
            teachersCacheKeyOf({
              sectionId,
              status,
            }),
          );
        });
      },
    },
  );

  return mutation;
};
