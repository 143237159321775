import * as React from "react";
import Icon from "../../../atoms/Icon";
import styles from "./StudentTagModal.scss";
import StudentTag from "../../../../domains/StudentTag";
import classNames from "classnames";
import { Student } from "../../../../domains/Student";
import { StudentModalState } from "./StudentModalState";

const getStudents = (studentModalState: StudentModalState) => {
  if (!studentModalState.selectedTag) return [];
  return studentModalState.studentsByTagId[studentModalState.selectedTag.id];
};

// statusタグの検索はOR検索になるため、「`active`かつ`inviting`」という検索はできないので、
// inviting以外のstatusを選択できないようにしておく
const isDisabledTagType = (
  tag: StudentTag,
  isForEntryGuidesForStudents: boolean,
) => {
  if (!isForEntryGuidesForStudents) return false; // 生徒向けでなければ（保護者向け）なら、禁止するtypeはない
  if (tag.type !== "status") return false;
  return tag.id !== "inviting";
};
const isDisabledTag = (tag: StudentTag, isForEntryGuidesForStudents: boolean) =>
  tag.studentCount === 0 || isDisabledTagType(tag, isForEntryGuidesForStudents);

const StudentsList = ({
  students,
  isForEntryGuidesForStudents,
}: {
  students: Student[];
  isForEntryGuidesForStudents: boolean;
}) => {
  return (
    <div>
      {students.length > 0 ? (
        students.map((student) => (
          <div
            key={`import-history-for-entry-guide-tag-student-${student.id}`}
            className={styles.student}
          >
            <Icon name="icon-navi-avatar" className={styles.student__icon} />
            <div className={styles.student__name}>{student.fullName}</div>
          </div>
        ))
      ) : (
        <div className={styles.student}>
          {isForEntryGuidesForStudents ? "招待中生徒" : "LINEが未連携の保護者"}
          が存在しません
        </div>
      )}
    </div>
  );
};

const StudentCount = ({ students }: { students: Student[] }) => {
  return <span>（{students.length}名）</span>;
};

const renderDestinationOption = (
  tag: StudentTag,
  isSelected: boolean,
  isOpened: boolean,
  students: Student[],
  addTag: (tag: StudentTag) => void,
  removeTag: () => void,
  openStudentsList: (tag: StudentTag) => void,
  closeStudentsList: () => void,
  isForEntryGuidesForStudents: boolean,
) => {
  const isDisabled = isDisabledTag(tag, isForEntryGuidesForStudents);
  return (
    <React.Fragment key={`StudentTag-${tag.id}`}>
      <label className={styles.label}>
        <div
          className={classNames(styles.row, {
            [styles.disabledRow]: isDisabled,
          })}
        >
          <input
            type="radio"
            name={tag.id}
            checked={isSelected}
            onChange={() => {
              if (isSelected) {
                removeTag();
              } else {
                addTag(tag);
              }
            }}
            disabled={isDisabled}
          />
          <div className={styles.tagName}>
            {tag.name}
            {isSelected && students ? <StudentCount students={students} /> : ""}
          </div>
        </div>
        {isOpened ? (
          <Icon
            name="icon-arrow-up"
            className={styles.icon}
            onClick={(e) => {
              e.preventDefault();
              if (!isDisabled) {
                closeStudentsList();
              }
            }}
          />
        ) : (
          <Icon
            name="icon-arrow-down"
            className={styles.icon}
            onClick={(e) => {
              e.preventDefault();
              if (!isDisabled) {
                openStudentsList(tag);
              }
            }}
          />
        )}
      </label>
      {isOpened && students ? (
        <StudentsList
          students={students}
          isForEntryGuidesForStudents={isForEntryGuidesForStudents}
        />
      ) : undefined}
    </React.Fragment>
  );
};

type Props = {
  tags: StudentTag[];
  studentModalState: StudentModalState;
  addTag: (tag: StudentTag) => void;
  removeTag: () => void;
  openStudentsList: (tag: StudentTag) => void;
  closeStudentsList: () => void;
  isForEntryGuidesForStudents: boolean;
};
export const StudentTagSelector = ({
  tags,
  studentModalState,
  addTag,
  removeTag,
  openStudentsList,
  closeStudentsList,
  isForEntryGuidesForStudents,
}: Props) => {
  const isSelectedTag = (tag: StudentTag) =>
    studentModalState.selectedTag?.id === tag.id;
  const isOpened = (tag: StudentTag) =>
    !studentModalState.isClosedSelectedStudentsList && isSelectedTag(tag);

  return (
    <div className={styles.tagSelectorRoot} data-testid="TagSelector">
      {tags.map((tag) => (
        <div key={`import-history-for-entry-guide-tag-${tag.id}`}>
          {renderDestinationOption(
            tag,
            isSelectedTag(tag),
            isOpened(tag),
            getStudents(studentModalState),
            addTag,
            removeTag,
            openStudentsList,
            closeStudentsList,
            isForEntryGuidesForStudents,
          )}
        </div>
      ))}
    </div>
  );
};
