import * as React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import Api from "../../api";
import StudentTag, { StudentTagType } from "../../domains/StudentTag";
import ApiResponse from "../../interfaces/ApiResponse";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { stringify } from "query-string";
import { useBoolean } from "../useBoolean";
import { createError, HTTPErrors } from "../../errors";

type UseFetchTagsProps = {
  sectionId: string;
  params: FetchTagsParams;
};

type FetchTagsParams = {
  types: readonly StudentTagType[];
};

type ResponseBody = ApiResponse<StudentTag>;

export const useFetchAllTags = ({ sectionId, params }: UseFetchTagsProps) => {
  const completeFetchAllTags = useBoolean();

  const queryParams = stringify(params, { arrayFormat: "bracket" });
  const url = `/api/v1/sections/${sectionId}/all_tags?${queryParams}`;

  const result = useInfiniteQuery<ResponseBody, HTTPErrors>(
    [url],
    async ({ pageParam = 1 }) => {
      const res = await Api.get(`${url}&page=${pageParam}`);
      if (res.ok) {
        const json = await res.json();
        return json.allTags as ResponseBody;
      }
      throw await createError(res);
    },
    {
      getNextPageParam,
    },
  );
  const data = result.data?.pages.flatMap((page) => page.data);

  // all_tags はページング前提のAPIなので、自動で最後のページまでリクエストし切る
  React.useEffect(() => {
    if (result.data) {
      const len = result.data.pages.length;
      const meta = result.data.pages[len - 1].meta;
      if (meta.totalPages > meta.currentPage) {
        result.fetchNextPage();
      } else {
        completeFetchAllTags.on();
      }
    }
  }, [result.data]);

  return { ...result, data, completeFetchAlltags: completeFetchAllTags.value };
};
