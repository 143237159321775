import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../api";
import { createError, HTTPErrors } from "../../errors";
import SectionInterface from "../../interfaces/SectionInterface";

type Props = {
  section: SectionInterface;
  onSuccess: () => void;
  onError: () => void;
};

export const useDeleteSectionSchedule = ({
  section,
  onSuccess,
  onError,
}: Props) => {
  const client = useQueryClient();

  return useMutation<unknown, HTTPErrors, string>(
    async (scheduleId) => {
      const res = await api.delete(urlOf(section, scheduleId));
      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    {
      onSuccess() {
        client.invalidateQueries(["section/schedules"]);
        onSuccess();
      },
      onError,
    },
  );
};

const urlOf = (section: SectionInterface, scheduleId: string) =>
  `/api/v1/sections/${section.id}/schedules/${scheduleId}`;
