import { ContentCourse } from "../../../../domains/ContentCourse";
import { ContentCourseStudent } from "../../../../domains/Student";
import { useFetchContentCourseStudents } from "./useFetchContentCourseStudents";
import { useDeleteContentCourseTag } from "./useDeleteContentCourseTag";
import { useParams } from "react-router";
import { HTTPErrors, matchHttpError } from "../../../../errors";
import { useErrorDispatcher } from "../../../../hooks/useErrorDispatcher";
import { useModalManager } from "../SelectDistributeTagModal";
import { useContentCourseDetailQuery } from "../../../../hooks/http/useContentCoursesApi";

type UseSectionsSettingsCourseStudentsHook = {
  course: ContentCourse | undefined;
  courseStudents: ContentCourseStudent[];
  isCourseLoading: boolean;
  isCourseStudentsLoading: boolean;
  isDeleting: boolean;
  onClickDelete: () => void;
  sectionId: string;
  onFetchNextPage: () => void;
} & ReturnType<typeof useModalManager>;

export const useSectionsSettingsCourseStudents =
  (): UseSectionsSettingsCourseStudentsHook => {
    const { sectionId = "", contentCourseId: courseId = "" } = useParams<{
      sectionId: string;
      contentCourseId: string;
    }>();
    const modalManagerState = useModalManager();
    const { sendNotFound, sendForbidden } = useErrorDispatcher();
    // コース詳細の取得
    const courseResult = useContentCourseDetailQuery({
      sectionId,
      courseId,
      onQueryError: (error: HTTPErrors) =>
        matchHttpError(error, {
          caseForbiden: sendForbidden,
          caseNotFound: sendNotFound,
        }),
    });
    // コース配信生徒一覧の取得
    const courseStudentsResult = useFetchContentCourseStudents({
      sectionId,
      courseId,
      onQueryError: (error: HTTPErrors) =>
        matchHttpError(error, {
          caseForbiden: sendForbidden,
          caseNotFound: sendNotFound,
        }),
    });
    // コース配信生徒一覧のパース
    const courseStudents = courseStudentsResult.data
      ? courseStudentsResult.data?.pages.reduce<ContentCourseStudent[]>(
          (prev, current) => [...prev, ...current.data],
          [],
        )
      : [];
    // コース配信対象タグ削除処理
    const { onDelete: onClickDelete, isDeleting } = useDeleteContentCourseTag(
      sectionId,
      courseId,
    );

    return {
      course: courseResult.course,
      courseStudents,
      isCourseLoading: courseResult.isLoading,
      isCourseStudentsLoading:
        courseStudentsResult.isLoading ||
        courseStudentsResult.isFetchingNextPage,
      onFetchNextPage: courseStudentsResult.fetchNextPage,
      isDeleting,
      onClickDelete,
      ...modalManagerState,
      sectionId,
    };
  };
