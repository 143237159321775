import StudentInterface from "../../../../interfaces/StudentInterface";
import { Modal } from "../../../../components/atoms/Modal";
import { BillingPlan, toJapanese } from "../../../../domains/BillingPlan";
import { Button } from "@studyplus/boron-ui";
import { useMutation } from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../../../errors";
import api from "../../../../api";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";

type Props = {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
  isOpen: boolean;
  closeModal: () => void;
  newBillingPlan: BillingPlan;
};

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/9153866";

export const StudentBillingPlanModal = (props: Props) => {
  const { mutate, isLoading } = useMutateStudentBillingPlan(
    props.student.id,
    props.updateStudent,
  );
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.closeModal}>
      <Modal.NoCloseHeader>利用プラン変更の確認</Modal.NoCloseHeader>
      <Modal.Body>
        <div className="mx-auto mb-12 mt-10 w-10/12 text-center">
          {props.newBillingPlan === "free" ? (
            <FreePlanBody studentName={props.student.fullName} />
          ) : (
            <ProPlanBody studentName={props.student.fullName} />
          )}
          <div className="mb-12 mt-10 flex flex-row justify-center gap-7">
            <Button variant="outline" onClick={props.closeModal}>
              キャンセル
            </Button>
            <Button
              onClick={() => {
                mutate(props.newBillingPlan, {
                  onSuccess: () => {
                    props.closeModal();
                    showSuccessMessage("利用プランを変更しました");
                  },
                  onError: () => {
                    showErrorMessage("利用プランを変更できませんでした");
                  },
                });
              }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {toJapanese(props.newBillingPlan)}に変更
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const useMutateStudentBillingPlan = (
  studentId: string,
  updateStudent: (student: StudentInterface) => void,
) => {
  return useMutation<StudentInterface, HTTPErrors, BillingPlan>(
    async (billingPlan) => {
      const path = `/api/v1/students/${studentId}/billing_plans`;
      const response = await api.patch(path, { billing_plan: billingPlan });
      if (response.ok) {
        const json = (await response.json()) as { student: StudentInterface };
        return json.student;
      }
      throw await createError(response);
    },
    {
      onSuccess(student: StudentInterface) {
        updateStudent(student);
      },
    },
  );
};

const FreePlanBody = ({ studentName }: { studentName: string }) => {
  return (
    <>
      <div className="mb-9">
        {studentName}さんを
        <span className="font-bold">フリー（無料）プラン</span>
        に変更してよろしいですか？
        <br />
        <span className="font-bold">フリープラン</span>
        にすると以下の機能が利用できなくなります。
      </div>
      <div className="rounded bg-gray-100 px-8 py-7 text-left">
        <ul>
          <li className="list-inside list-disc">
            タイムライン機能の生徒自身が記録した学習時間の閲覧
          </li>
          <li className="list-inside list-disc">
            アナリティクス機能の生徒自身が記録した学習時間の閲覧
          </li>
          <li className="list-inside list-disc">
            タイムラインのコメント、いいね機能
          </li>
          <li className="list-inside list-disc">プランニング機能</li>
          <li className="list-inside list-disc">課題配信機能</li>
        </ul>
      </div>
      <div className="mt-9">
        <a
          href={HELP_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-blue-400 underline"
        >
          利用プランについて詳しくはこちら
        </a>
      </div>
    </>
  );
};

const ProPlanBody = ({ studentName }: { studentName: string }) => {
  return (
    <>
      <div className="mb-9">
        {studentName}さんを
        <span className="font-bold">プロ（有料）プラン</span>
        に変更してよろしいですか？
        <br />
        <span className="font-bold">プロプラン</span>
        にすると全ての機能が利用可能になります。
      </div>
      <div>
        <a
          href={HELP_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-blue-400 underline"
        >
          利用プランについて詳しくはこちら
        </a>
      </div>
    </>
  );
};
