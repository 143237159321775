import * as React from "react";
import { RouterProps } from "react-router";

import TabsNavi from "../../../components/molecules/TabsNavi";
import ImportOperation from "../../../components/organisms/ImportOperation";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { useErrorDispatcher } from "../../../hooks/useErrorDispatcher";
import {
  ImportHistoryInterface,
  Operations,
  isEntryGuideOperation,
} from "../../../domains/ImportHistory";
import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./styles.scss";
import ImportOperationForEntryGuide from "../../../components/features/ImportHistory/ImportOperationForEntryGuide";

interface OwnProps extends WithRouterProps<{ importOperation: string }> {
  section: SectionInterface;
}

type Props = OwnProps & RouterProps & OutletContextProps;

const isImportOperation = (
  importOperation: string,
): importOperation is ImportHistoryInterface["operation"] => {
  return Operations.filter(
    // ドリル教材の一括操作画面はないので404ページに遷移させるためのfilter
    (ope) => ope !== "drill_learning_material_insert",
  ).some((ope) => ope === importOperation);
};

const isForbiddenOperation = (
  importOperation: string,
  section: SectionInterface,
): boolean => {
  // LINE利用設定がOFFの場合は、LINE連携向け機能である初回配布資料の保護者向けは表示しない
  return (
    !section.guardianLineConnectionUsable &&
    importOperation === "entry_guides_for_guardians"
  );
};

const SectionsSettingsImportOperationPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("import_histories");
  }, []);

  const { sendNotFound } = useErrorDispatcher();
  if (!props.section) {
    return null;
  }
  const { importOperation } = props.params;

  if (
    !isImportOperation(importOperation) ||
    isForbiddenOperation(importOperation, props.section)
  ) {
    sendNotFound();
    return null;
  }

  return (
    <div>
      <TabsNavi
        tabs={[
          {
            label: "一括操作",
            link: `/sections/${props.section.id}/settings/import_histories/operations`,
            active: true,
          },
          {
            label: "操作履歴",
            link: `/sections/${props.section.id}/settings/import_histories`,
            active: false,
          },
        ]}
      />
      <div className={styles.list}>
        {isEntryGuideOperation(importOperation) ? (
          <ImportOperationForEntryGuide
            section={props.section}
            operation={importOperation}
          />
        ) : (
          <ImportOperation
            section={props.section}
            operation={importOperation}
          />
        )}
      </div>
    </div>
  );
};

const pageInfo = {
  title: "一括操作",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsImportOperationPage,
  pageInfo,
  ["import_history:index"],
);

export default EnhancedPage;
