import { useInfiniteQuery } from "@tanstack/react-query";
import ApiResponse from "../../../interfaces/ApiResponse";
import { AnalyticsLectureAttendanceLecture } from "../../../domains/SectionAnalytics";
import { createError, HTTPErrors } from "../../../errors";
import ApiClient from "../../../api";
import { toQueryString } from "../../../helpers/TimeHelper";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { AnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";

type Props = {
  sectionId: string;
  analyticsFilter: AnalyticsFilter;
};
export const useFetchLectureAnalytics = ({
  sectionId,
  analyticsFilter,
}: Props) => {
  const {
    data,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useInfiniteQuery<
    ApiResponse<AnalyticsLectureAttendanceLecture>,
    HTTPErrors
  >(
    lectureAttendanceLectureAnalyticsCacheKeyOf({ sectionId, analyticsFilter }),
    async ({ pageParam }) => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/analytics/lecture_attendances/lectures`,
        {
          query: {
            initial_date:
              analyticsFilter?.endDate &&
              toQueryString(analyticsFilter.endDate),
            term: analyticsFilter?.term,
            order: analyticsFilter?.order,
            order_dir: analyticsFilter?.orderDir,
            page: pageParam,
          },
        },
      );
      if (response.ok) {
        const json = (await response.json()) as {
          lectureAttendancesAnalyticsTable: ApiResponse<AnalyticsLectureAttendanceLecture>;
        };
        return json.lectureAttendancesAnalyticsTable;
      } else {
        throw await createError(response);
      }
    },
    {
      getNextPageParam,
      enabled: !!analyticsFilter,
    },
  );
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage]);

  const analyticsTableColumns = data?.pages[0].data.columns;
  const analyticsTableRows = data?.pages.flatMap((page) => page.data.lectures);
  const allScores = data?.pages.flatMap((page) =>
    page.data.lectures.flatMap((lecture) => lecture.scores),
  );

  return {
    isFetching,
    isFetchingNextPage,
    analyticsTableColumns,
    analyticsTableRows,
    allScores,
  };
};

const lectureAttendanceLectureAnalyticsCacheKeyOf = ({
  sectionId,
  analyticsFilter,
}: Props) => {
  return ["lectureAttendanceLectureAnalyticsTable", sectionId, analyticsFilter];
};
