import SectionInterface from "../../interfaces/SectionInterface";
import { useNavigate } from "react-router-dom";
import { UnprocessableEntityError } from "../../errors";
import { useQueryError } from "../../hooks/http/useQueryError";
import { FormikErrors, setIn, useFormik } from "formik";
import { ChangeEvent, useEffect } from "react";
import { Button } from "@studyplus/boron-ui";
import * as yup from "yup";
import Input from "../../components/atoms/Input";
import { usePostDrillRegistrationCodes } from "./usePostDrillRegistrationCodes";
import { useFlashMessage } from "../../hooks/useFlashMessage";

type FormSchema = {
  drillRegistrationCodes: string[];
  nonFieldErrors?: string[]; // drillRegistrationCodesが空の場合に特定のフィールドに依らないエラー表示にするために使う
};
const initialValues: FormSchema = {
  // あらかじめ10個分作っておく
  drillRegistrationCodes: Array.from({ length: 10 }).map(() => ""),
};

const validationSchema = yup
  .object<Record<keyof FormSchema, yup.AnyObjectSchema>>()
  .shape({
    drillRegistrationCodes: yup.array().of(
      yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9]{9}$/, "９文字の半角英数字でご入力ください")
        .matches(/^c/, "先頭の文字をcにしてください")
        .test("unique", "重複しているコードがあります", (value, context) => {
          if (value === "" || value == null) return true;
          // 重複しているフィールドのみにエラーを表示したいので、array全体ではなくcontextを使ってアイテムレベルでバリデートしている
          const codes = context.parent as string[];
          return codes.filter((code) => code === value).length === 1;
        }),
    ),
  });

// サーバーからのエラーをformik.errorsにマージする
const useMergeServerError = (
  error: Error | null | UnprocessableEntityError,
  setErrors: (errors: FormikErrors<FormSchema>) => void,
  drillRegistrationCodes: readonly string[],
) => {
  // formik.errorsにサーバーからのエラーを追加
  useEffect(() => {
    if (error instanceof UnprocessableEntityError) {
      const errors = error.originalErrors.reduce<FormikErrors<FormSchema>>(
        (acc, error) => {
          const index = drillRegistrationCodes.findIndex(
            (code) => code === error.resource,
          );
          if (index === -1) return acc;

          return setIn(acc, `drillRegistrationCodes.${index}`, error.message);
        },
        {},
      );
      setErrors(errors);
    }
  }, [error]);
};

const removeEmpty = (
  drillRegistrationCodes: FormSchema["drillRegistrationCodes"],
) => drillRegistrationCodes.filter((code) => code !== "");

const validate = (values: FormSchema) => {
  const errors: FormikErrors<FormSchema> = {};
  const codes = removeEmpty(values.drillRegistrationCodes);
  if (codes.length === 0) {
    errors.nonFieldErrors = "コードを入力してください";
  }
  return errors;
};

export const DrillRegistrationCodeForm = ({
  section,
}: {
  section: SectionInterface;
}) => {
  const navigate = useNavigate();
  const { showSuccessMessage } = useFlashMessage();

  const { isLoading, mutate, error } = usePostDrillRegistrationCodes({
    section,
    onSuccess: () => {
      showSuccessMessage("登録に成功しました");
      navigate(`/sections/${section.id}/settings/digital_learning_materials`);
    },
  });

  // 422エラーはフォームにエラー表示をするのでハンドリングしない
  const handlingError =
    error instanceof UnprocessableEntityError ? null : error;
  useQueryError(handlingError);

  const formik = useFormik<FormSchema>({
    initialValues: initialValues,
    enableReinitialize: true,
    validate,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (formValue) => {
      // 空文字を除外
      const codes = removeEmpty(formValue.drillRegistrationCodes);
      if (codes.length === 0) return;

      mutate(codes);
    },
  });

  useMergeServerError(
    error,
    formik.setErrors,
    formik.values.drillRegistrationCodes,
  );

  return (
    <form
      onSubmit={formik.handleSubmit}
      aria-label="デジタル教材登録コード入力フォーム"
    >
      <div className="mt-9">
        <span>デジタル教材登録コードの入力</span>
        <span className="ml-4 text-sm text-black">
          ※1回に10件まで登録できます
        </span>
      </div>
      <div className="mt-5 rounded-lg bg-gray-100 p-7">
        {Object.keys(formik.errors).length > 0 && (
          <ErrorMessage errors={formik.errors} />
        )}
        <div className="grid grid-cols-2 gap-7">
          {formik.values.drillRegistrationCodes.map((code, i) => (
            <DrillRegistrationCodeField
              key={i}
              index={i}
              onChange={formik.handleChange}
              value={code}
              hasError={Boolean(formik.errors.drillRegistrationCodes?.[i])}
              errorMessage={formik.errors.drillRegistrationCodes?.[i]}
            />
          ))}
        </div>
      </div>
      <div className="mt-7 flex justify-end">
        <div className="w-[10rem]">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={Object.keys(formik.errors).length > 0}
            isBlock
          >
            登録
          </Button>
        </div>
      </div>
    </form>
  );
};

const ErrorMessage = (props: { errors: FormikErrors<FormSchema> }) => (
  <div className="mb-8">
    <p className="font-bold text-darkred-500">
      {props.errors.nonFieldErrors ?? "入力内容にエラーがあります。"}
    </p>
  </div>
);

const DrillRegistrationCodeField = (props: {
  index: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  hasError: boolean;
  errorMessage?: string;
}) => {
  const name = `drillRegistrationCodes.${props.index}`;
  return (
    <div>
      <Input
        placeholder="9文字のコードを入力"
        id={name}
        name={name}
        type="text"
        onChange={props.onChange}
        value={props.value}
        hasError={props.hasError}
      />
      {props.hasError && (
        <p className="mt-1 text-sm text-darkred-500">{props.errorMessage}</p>
      )}
    </div>
  );
};
