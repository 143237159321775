import styles from "./styles.scss";
import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../../../api";
import StudentInterface from "../../../../interfaces/StudentInterface";

const reasons = [
  {
    type: "withdrawn",
    label: "生徒の所属がなくなったため（退塾・退学・転学）",
  },
  { type: "suspended", label: "生徒がお休みしているため（休塾・休学）" },
  { type: "graduated", label: "生徒が卒業するため" },
  { type: "course_decided", label: "生徒の進路が決定したため" },
  { type: "section_changed", label: "生徒の所属教室・コースが変更になるため" },
  { type: "inactive_student", label: "生徒が学習記録を入力しないため" },
  {
    type: "cannot_login",
    label: "携帯電話を機種変更した、ログインできなくなったため",
  },
  { type: "test_account", label: "テストアカウントとして登録したため" },
  { type: "incorrect_student", label: "生徒登録を誤ったため" },
  {
    type: "canceled_studyplus_for_school",
    label: "Studyplus for Schoolの法人契約を解約するため",
  },
  { type: "other", label: "その他" },
] as const;

type ReasonType = (typeof reasons)[number]["type"] | null;

const REASON_TEXT_MAX_LENGTH = 255;

type Props = {
  student: StudentInterface;
  onSubmitSuccess: () => void;
  updateStudent: (student: StudentInterface) => void;
};
export const DisconnectAppForm = ({
  student,
  onSubmitSuccess,
  updateStudent,
}: Props) => {
  const [selectedReason, setSelectedReason] = React.useState<ReasonType>(null);
  const [reasonText, setReasonText] = React.useState<string>("");
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const isValid = () => {
    if (!selectedReason) {
      return false;
    }

    if (selectedReason === "other") {
      return (
        reasonText.length > 0 && reasonText.length <= REASON_TEXT_MAX_LENGTH
      );
    }

    return true;
  };

  const resetForm = () => {
    setSelectedReason(null);
    setReasonText("");
  };

  const handleChangeReasonText = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setReasonText(e.target.value);
  };

  const { mutate, isLoading } = useMutation<
    void,
    void,
    {
      reason: ReasonType;
      reasonText: string;
    }
  >({
    mutationFn: async ({ reason, reasonText }) => {
      if (reason == null) throw new Error("reason is required");

      const { response } = await boronClient.DELETE(
        `/api/v1/students/{student_id}/passcodes`,
        {
          params: {
            path: { student_id: student.id },
          },
          body: { reason, reason_text: reasonText },
        },
      );
      if (response.ok) {
        resetForm();
        showSuccessMessage("アプリ連携を停止しました");
        onSubmitSuccess();
        updateStudent({
          ...student,
          status: "inactive",
          statusHuman: "停止中",
        });
        return;
      }
      showErrorMessage("アプリ連携を停止できませんでした");
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedReason) {
      return;
    }

    const params = {
      reason: selectedReason,
      reasonText,
    };
    mutate(params);
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className={styles.fieldset}>
        <h3 className={styles.fieldset__heading}>生徒アカウント連携停止理由</h3>
        {reasons.map(({ type, label }) => (
          <ReasonRadio
            key={type}
            reasonType={type}
            checked={selectedReason === type}
            onCheck={setSelectedReason}
          >
            {label}
          </ReasonRadio>
        ))}
        <textarea
          placeholder={"理由を入力"}
          className={styles.textarea}
          onChange={handleChangeReasonText}
          value={selectedReason === "other" ? reasonText : ""}
          disabled={selectedReason !== "other"}
        />
        <ReasonTextError reasonText={reasonText} />
      </fieldset>
      <Button
        disabled={!isValid()}
        isLoading={isLoading}
        className={styles.button}
        type="submit"
      >
        連携を停止
      </Button>
    </form>
  );
};

const ReasonRadio = ({
  reasonType,
  checked,
  onCheck,
  children,
}: {
  reasonType: ReasonType;
  checked: boolean;
  onCheck: (reasonType: ReasonType) => void;
  children: React.ReactNode;
}) => {
  if (!reasonType) {
    return null;
  }
  return (
    <div className={styles.reason}>
      <input
        type="radio"
        name="reason"
        value={reasonType}
        id={`reason_${reasonType}`}
        checked={checked}
        onChange={() => onCheck(reasonType)}
      />
      <label htmlFor={`reason_${reasonType}`}>{children}</label>
    </div>
  );
};

const ReasonTextError = ({ reasonText }: { reasonText: string }) => {
  if (Array.from(reasonText).length > REASON_TEXT_MAX_LENGTH) {
    return (
      <p className={styles.error}>
        {`理由は${REASON_TEXT_MAX_LENGTH}文字以内でご記入ください。`}
      </p>
    );
  } else {
    return null;
  }
};
