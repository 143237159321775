import {
  ErrorMessage,
  Field,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import * as React from "react";
import { connect, ResolveThunks } from "react-redux";
import * as yup from "yup";

import { postImportOperation } from "../../../actions/pages/SectionsSettingsImportOperationPage";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { ImportHistoryInterface } from "../../../domains/ImportHistory";
import SectionInterface from "../../../interfaces/SectionInterface";
import { SectionsSettingsImportOperationStateInterface } from "../../../reducers/pages/SectionsSettingsImportOperationPage";
import { Button } from "@studyplus/boron-ui";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import styles from "./styles.scss";
import { UnprocessableEntityErrorItem } from "../../../errors";

interface StateProps {
  submitting: boolean;
  apiErrors: SectionsSettingsImportOperationStateInterface["errors"];
}
interface DispatchProps {
  postImportOperation: typeof postImportOperation;
}

interface OwnProps extends WithRouterProps {
  section: SectionInterface;
  operation: ImportHistoryInterface["operation"];
}

type Props = StateProps & ResolveThunks<DispatchProps> & OwnProps;

interface Values {
  csv: File | undefined;
  zip: File | undefined;
}

const validationSchema = (requireZip: boolean) => {
  return yup.object().shape({
    csv: yup.mixed().required("CSVファイルは必須です"),
    zip: requireZip
      ? yup.mixed().required("ZIP画像ファイルは必須です")
      : yup.mixed(),
  });
};

const UploadForm = (props: Props): JSX.Element => {
  let title = "2. 情報を保存したCSVファイルをアップロード";
  let requireZip = false;
  const { operation } = props;
  switch (operation) {
    case "student_insert":
    case "student_update":
    case "tag_insert":
    case "tag_update":
    case "student_tag_insert":
    case "student_tag_delete":
    case "bookshelf_insert":
    case "drill_learning_material_insert": // TODO: デジタル教材の一括操作実装時に修正
    case "student_billing_plan_update":
    case "examination_result_insert":
      break;
    case "learning_material_insert":
    case "learning_material_update":
      title = "2. 情報を保存したCSVファイルと画像のZIPファイルをアップロード";
      requireZip = true;
      break;
  }
  return (
    <div className={styles.box}>
      <div className={styles.boxHeading}>
        <span className={styles.boxHeadingTitle}>{title}</span>
        <span className={styles.notice}>
          ※エクセル形式ではインポートできませんので、必ずCSV形式で保存してください。
        </span>
      </div>
      <div className={styles.buttons}>
        <Formik
          initialValues={{ csv: undefined, zip: undefined } as Values}
          validationSchema={validationSchema(requireZip)}
          onSubmit={handleSubmit(props)}
          render={renderForm(requireZip)}
        />
      </div>
    </div>
  );
};

const renderForm =
  (requireZip: boolean) =>
  (props: FormikProps<Values>): JSX.Element => {
    return (
      <form className={styles.uploadForm} onSubmit={props.handleSubmit}>
        <div className={styles.uploadFormFields}>
          <Label className={styles.uploadFormLabel} htmlFor="csv">
            CSVファイル
          </Label>
          <Field
            id="csv"
            component={Input}
            name="csv"
            type="file"
            accept=".csv,text/csv"
            onChange={handleChangeFile(props, "csv")}
          />
          <ErrorMessage
            name="csv"
            component="div"
            className={styles.errorMessage}
          />
          {requireZip ? (
            <>
              <Label className={styles.uploadFormLabel} htmlFor="zip">
                画像ZIPファイル
              </Label>
              <Field
                id="zip"
                component={Input}
                name="zip"
                type="file"
                accept=".zip,application/zip"
                onChange={handleChangeFile(props, "zip")}
              />
              <ErrorMessage
                name="zip"
                component="div"
                className={styles.errorMessage}
              />
            </>
          ) : null}
        </div>
        <div className={styles.uploadFormButton}>
          <Button
            disabled={!props.isValid || props.isSubmitting}
            isBlock={true}
            type="submit"
          >
            インポート
          </Button>
        </div>
      </form>
    );
  };

const setErrors =
  (setFieldError: FormikHelpers<Values>["setFieldError"]) =>
  (errors: UnprocessableEntityErrorItem[]) => {
    errors.forEach((error) => {
      if (
        error.resource === "ImportHistoryAttachments[0]" &&
        error.field === "file"
      ) {
        setFieldError("csv", `CSVファイル${error.message}`);
      } else if (
        error.resource === "ImportHistoryAttachments[1]" &&
        error.field === "file"
      ) {
        setFieldError("zip", `画像ZIPファイル${error.message}`);
      }
    });
  };

const handleSubmit =
  (props: Props) => (values: Values, formikAction: FormikHelpers<Values>) => {
    props.postImportOperation(
      props.section.id,
      props.operation,
      values.csv,
      values.zip,
      props.navigate,
      setErrors(formikAction.setFieldError),
      formikAction.setSubmitting,
    );
  };

const handleChangeFile =
  (props: FormikProps<Values>, fieldName: string) =>
  (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { setFieldTouched, setFieldValue } = props;
    if (e.currentTarget && e.currentTarget.files) {
      setFieldValue(fieldName, e.currentTarget.files[0]);
      setFieldTouched(fieldName, true, false);
    } else {
      setFieldValue(fieldName, undefined);
      setFieldTouched(fieldName, true, false);
    }
  };

const actions: DispatchProps = {
  postImportOperation,
};

const mapStateToProps = (state: AppStateInterface): StateProps => ({
  submitting: state.sectionsSettingsImportOperation.submitting,
  apiErrors: state.sectionsSettingsImportOperation.errors,
});

export default withRouter<Props>(
  connect<StateProps, DispatchProps, OwnProps, AppStateInterface>(
    mapStateToProps,
    actions,
  )(UploadForm),
);
