import { useInfiniteQuery } from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../errors";
import ApiClient from "../../api";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { ContentResults } from "../../domains/ContentCourseContentResult";

type Props = {
  sectionId: string;
  courseId: string;
  unitId: string;
};
export const useFetchContentCourseContentResults = ({
  sectionId,
  courseId,
  unitId,
}: Props) => {
  const { data, isLoading, ...result } = useInfiniteQuery<
    ContentResults,
    HTTPErrors
  >(
    [fetchUrl(sectionId, courseId, unitId)],
    async ({ pageParam = 1 }) => {
      const response = await ApiClient.get(
        fetchUrl(sectionId, courseId, unitId),
        {
          query: {
            page: pageParam,
          },
        },
      );
      if (response.ok) {
        const json = await response.json();
        return json.contentResults as ContentResults;
      }
      throw await createError(response);
    },
    {
      getNextPageParam,
    },
  );

  const results: ContentResults | undefined = data
    ? {
        ...data.pages.slice(-1)[0],
        data: data.pages.flatMap((page) => page.data),
      }
    : undefined;
  return {
    ...result,
    data: results,
    isLoading,
  };
};

const fetchUrl = (sectionId: string, courseId: string, unitId: string) =>
  `/api/v1/sections/${sectionId}/contents/courses/${courseId}/units/${unitId}/results`;
