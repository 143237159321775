import * as React from "react";
import styles from "./SectionsLectureTransferForm.styles.scss";
import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../components/atoms/Flex";
import Label from "../../components/atoms/Label";
import { SingleDatePickerField } from "../../components/atoms/SingleDatePickerField/index";
import { BlockRow } from "../../components/atoms/BlockRow/index";
import { useFormik } from "formik";
import { SelectWrapper } from "../../components/general/SelectWrapper/index";
import { LectureAttendanceWithSchedule } from "../../domains/LectureAttendance";
import { useFetchLectureSessions } from "./useFetchLectureSessions";
import SectionInterface from "../../interfaces/SectionInterface";
import { useMutateLectureTransfer } from "./useMutateLectureTransfer";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  MainSection,
  PageHeader,
} from "../../components/atoms/MainSection/index";
import ErrorText from "../../components/atoms/ErrorText";
import { StylesConfig } from "react-select";
import {
  OptionType,
  customStyles as defaultStyles,
} from "../../components/general/SelectWrapper/styles";
import format from "date-fns/format";

// NOTE: 将来的には対象生徒と振替元も編集可能にする想定。
// そのため、振替元を編集可能かどうかのフラグ(isOriginEditable)をもらえるようにしていますが、現状は利用していません。
// 今後編集可能にする場合は、対象生徒と振替元講座予定のリストを取得して保持する必要があります。
type Props = {
  section: SectionInterface;
  isOriginEditable: boolean;
  originLectureAttendance: LectureAttendanceWithSchedule;
};

const valueContainerStyles: StylesConfig<OptionType>["valueContainer"] = (
  styles,
  state,
) => {
  return defaultStyles.valueContainer === undefined
    ? styles
    : {
        ...defaultStyles.valueContainer(styles, state),

        // NOTE: 通常はisDisabled時に50%透過するが、ここではテキストを表示したいため例外的に透過しない
        opacity: 1,
      };
};
const customStyles = { valueContainer: valueContainerStyles };

export const SectionsLectureTransferForm = ({
  isOriginEditable,
  originLectureAttendance,
  section,
}: Props) => {
  const form = useForm({ originLectureAttendance, section });

  return (
    <MainSection>
      <PageHeader>振替受講の登録</PageHeader>
      <BlockRow marginTop="2.4rem">
        <form onSubmit={form.handleSubmit} aria-label="振替受講の登録">
          {/* 対象生徒 */}
          <Label isMute>対象生徒</Label>
          <SelectWrapper
            isDisabled={!isOriginEditable}
            options={form.targetStudentOptions}
            value={form.targetStudentOptions.find(
              (option) => option.value === form.values.targetStudentId,
            )}
            size="md"
            fontSize="md"
            styles={customStyles}
          />

          {/* 振替元講座予定 */}
          <BlockRow marginTop="1.6rem">
            <Label isMute noMargin>
              振替元講座予定
            </Label>
            <Flex gap={"1"} alignItems="flex-start" marginTop="0.8rem">
              <SingleDatePickerField
                className={styles.datePicker}
                disabled={!isOriginEditable}
                value={form.values.originLectureSessionHeldAt || new Date()}
                dateFormat="yyyy年M月d日"
              />
              <div className={styles.selectBoxContainer}>
                <SelectWrapper
                  isDisabled={!isOriginEditable}
                  options={form.originLectureSessionOptions}
                  value={form.originLectureSessionOptions.find(
                    (option) =>
                      option.value === form.values.originLectureSessionId,
                  )}
                  size="md"
                  fontSize="md"
                  placeholder="振替元講座予定を選択してください"
                  noOptionsMessage={() => "講座予定がありません"}
                  className={styles.selectBox}
                  styles={customStyles}
                />
                <ErrorText>{form.errors.originLectureSessionId}</ErrorText>
              </div>
            </Flex>
          </BlockRow>

          {/* 振替先講座予定 */}
          <BlockRow marginTop="1.6rem">
            <Label isMute htmlFor="destinationLectureSession" noMargin>
              振替先講座予定
            </Label>
            <Flex gap={"1"} alignItems="flex-start" marginTop="0.8rem">
              <SingleDatePickerField
                className={styles.datePicker}
                value={
                  form.values.destinationLectureSessionHeldAt || new Date()
                }
                dateFormat="yyyy年M月d日"
                onDateChange={(date) => {
                  form.setFieldValue("destinationLectureSessionHeldAt", date);
                }}
              />
              <div className={styles.selectBoxContainer}>
                <SelectWrapper
                  inputId="destinationLectureSession"
                  options={form.destinationLectureSessionOptions}
                  value={
                    form.destinationLectureSessionOptions.find(
                      (option) =>
                        option.value ===
                        form.values.destinationLectureSessionId,
                    ) || null
                  }
                  size="md"
                  placeholder="振替先講座予定を選択してください"
                  noOptionsMessage={() => "講座予定がありません"}
                  onChange={(option) => {
                    form.setFieldValue(
                      "destinationLectureSessionId",
                      option?.value,
                    );
                  }}
                  className={styles.selectBox}
                  fontSize="md"
                  styles={customStyles}
                />
                <ErrorText>{form.errors.destinationLectureSessionId}</ErrorText>
              </div>
            </Flex>
          </BlockRow>

          <Flex marginTop="2rem" justifyContent="flex-end">
            {/* 登録 */}
            <Button
              type="submit"
              isLoading={form.isSubmitting}
              disabled={form.isSubmitting || !form.isValid}
            >
              登録
            </Button>
          </Flex>
        </form>
      </BlockRow>
    </MainSection>
  );
};

type Values = {
  targetStudentId: string | null;
  originLectureSessionId: string;
  originLectureSessionHeldAt: Date;
  destinationLectureSessionId: string;
  destinationLectureSessionHeldAt: Date;
};
const buildInitialValues = ({
  originLectureAttendance,
}: Pick<FormProps, "originLectureAttendance">): Values => {
  return {
    targetStudentId: originLectureAttendance.student.id,
    originLectureSessionId: originLectureAttendance.lectureSessionId,
    originLectureSessionHeldAt: originLectureAttendance.schedule.startAt,
    destinationLectureSessionId: "",
    destinationLectureSessionHeldAt: new Date(),
  };
};
const validationSchema = yup.object().shape({
  targetStudentId: yup.string().required("対象生徒を選択してください"),
  originLectureSessionId: yup
    .string()
    .trim()
    .required("振替元講座予定を選択してください"),
  destinationLectureSessionId: yup
    .string()
    .trim()
    .required("振替先講座予定を選択してください"),
});

type FormProps = Pick<Props, "originLectureAttendance" | "section">;
const useForm = ({ originLectureAttendance, section }: FormProps) => {
  const { mutate, isLoading: isSubmitting } = useMutateLectureTransfer({
    sectionId: section.id,
  });
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const navigate = useNavigate();
  const formik = useFormik<Values>({
    initialValues: buildInitialValues({ originLectureAttendance }),
    validationSchema,
    onSubmit(values: Values) {
      if (
        values.targetStudentId &&
        values.originLectureSessionId &&
        values.destinationLectureSessionId
      ) {
        mutate(
          {
            studentId: values.targetStudentId,
            originLectureSessionId: values.originLectureSessionId,
            destinationLectureSessionId: values.destinationLectureSessionId,
          },
          {
            onSuccess() {
              showSuccessMessage("振替受講を登録しました");
              navigate(
                `/sections/${section.id}/lecture_sessions/${values.originLectureSessionId}/attendees`,
              );
            },
            onError() {
              showErrorMessage("振替受講を登録できませんでした");
            },
          },
        );
      }
    },
  });

  const { data: lectureSessions } = useFetchLectureSessions({
    sectionId: section.id,
    dateString: format(
      formik.values.destinationLectureSessionHeldAt ?? new Date(),
      "yyyy-MM-dd",
    ),
  });

  const targetStudentOptions = [
    {
      label: originLectureAttendance.student.fullName,
      value: originLectureAttendance.student.id,
    },
  ];

  const originLectureSessionOptions = [
    {
      label: originLectureAttendance.schedule.summary,
      value: originLectureAttendance.lectureSessionId,
    },
  ];

  const destinationLectureSessionOptions = lectureSessions
    ? lectureSessions
        .filter(
          (lectureSession) =>
            lectureSession.id !== formik.values.originLectureSessionId,
        )
        .map((lectureSession) => ({
          label: lectureSession.schedule.summary,
          value: lectureSession.id,
        }))
    : [];

  React.useEffect(() => {
    formik.setFieldValue("destinationLectureSessionId", "", false);
  }, [formik.values.destinationLectureSessionHeldAt]);

  return {
    ...formik,
    targetStudentOptions,
    originLectureSessionOptions,
    destinationLectureSessionOptions,
    isSubmitting,
  };
};
