import { useMutation } from "@tanstack/react-query";
import ApiErrorResponseInterface from "../../interfaces/ApiErrorResponseInterface";
import ApiClient from "../../api";
import { createError } from "../../errors";

type MutateErrors = ApiErrorResponseInterface;
type Props = {
  onSuccess: () => void;
  onError: () => void;
};
export const useMutateConsentAgreement = ({ onSuccess, onError }: Props) => {
  return useMutation<void, MutateErrors, void>(
    async () => {
      const res = await ApiClient.patch("/api/v1/consent_agreement");
      if (res.ok) {
        // このAPIにレスポンスはなく204を返してくるのみ
        return;
      }

      throw await createError(res);
    },
    { onSuccess, onError },
  );
};
