import { useMutation } from "@tanstack/react-query";
import api from "../../api";
import { createError, HTTPErrors } from "../../errors";
import SectionInterface from "../../interfaces/SectionInterface";
import {
  DrillLearningMaterialValue,
  Values as StudyTaskParams,
} from "./useStudyTaskForm";
import { endOfDay } from "date-fns";
import { paths } from "../../lib/api/v1";

type RequestBody =
  paths["/api/v1/sections/{section_id}/study_tasks"]["post"]["requestBody"]["content"]["application/json"];

type Props = {
  section: SectionInterface;
  onError: (error: HTTPErrors) => void;
  onSuccess: () => void;
};

export const useMutateStudyTask = ({ onError, onSuccess, section }: Props) => {
  const url = `/api/v1/sections/${section.id}/study_tasks`;
  return useMutation<unknown, HTTPErrors, StudyTaskParams>(
    async (p) => {
      const res = await api.post(url, toRequestBody(p));
      if (res.status === 200) {
        const message =
          "課題の対象生徒に、選択教材が本棚登録されていない生徒が含まれています。\n課題を登録すると本棚に教材が自動登録されますが、このデジタル教材が有償教材の場合、利用料が発生します。詳しくは、デジタル教材に関する注意事項をご確認ください。※無償教材の場合、利用料は発生しません。\n\n対象生徒の本棚に教材を登録してもよろしいですか？";

        if (!window.confirm(message)) return false;
        const param = { ...p, confirmed: true };
        const confirmedResponse = await api.post(url, toRequestBody(param));
        if (confirmedResponse.status === 201) {
          return true;
        }
        throw await createError(confirmedResponse);
      } else if (res.status === 201) {
        return true;
      }
      throw await createError(res);
    },
    {
      onError,
      // confirmキャンセル時にフラッシュを出さないようにする
      onSuccess(data) {
        if (data) {
          onSuccess();
        } else {
          return;
        }
      },
    },
  );
};

const toRequestBody = (param: StudyTaskParams): RequestBody => {
  return {
    confirmed: param.confirmed,
    student_ids: param.students?.map((student) => student.value) || [],
    study_tasks: param.study_tasks.map((study_task) => {
      return {
        title: study_task.title,
        start_at: study_task.start_at.toISOString(),
        end_at: endOfDay(study_task.end_at).toISOString(),
        drill_learning_material: {
          material_code:
            study_task.drill_learning_material.learningMaterialCode || "",
          contents: toRequestContents(
            study_task.drill_learning_material.sections,
          ),
        },
      };
    }),
  };
};

const toRequestContents = (
  sections: DrillLearningMaterialValue["sections"],
): RequestBody["study_tasks"][0]["drill_learning_material"]["contents"] => {
  return sections.map((section) => ({
    drill_section_id: section.id,
    drill_content_ids: section.contents?.map((content) => content.id) || [],
  }));
};
