import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation";
import { boronClient } from "../../../api";
import { FSHTTPError } from "../../../errors";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { LinkedGoogleClassroomCourseInterface } from "../../../interfaces/SectionsSettingsGoogleClassroomInterface";

export const useUnlinkCourse = ({ sectionId }: { sectionId: string }) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate, isLoading } = useBoronMutation(
    async ({ courseId }: { courseId: string }) =>
      await boronClient.DELETE(
        "/api/v1/sections/{section_id}/google_classroom/courses/{google_classroom_course_hashid}",
        {
          params: {
            path: {
              section_id: sectionId,
              google_classroom_course_hashid: courseId,
            },
          },
        },
      ),
    {
      onSuccess: () => {
        showSuccessMessage("Google Classroomクラスの連携を解除しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage(
            "Google Classroomクラスの連携を解除できませんでした",
          );
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );

  const requestUnlinkCourse = ({
    course,
  }: {
    course: LinkedGoogleClassroomCourseInterface;
  }) => {
    if (window.confirm(`${course.name}との連携を解除してもよろしいですか？`)) {
      mutate({ courseId: course.id });
    }
  };

  return { requestUnlinkCourse, isLoading };
};
