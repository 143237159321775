import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../api";
import { createError, HTTPErrors } from "../../../errors";
import StaffBoardPostInterface from "../../../interfaces/StaffBoardPostInterface";

type Props = {
  sectionId: string;
};

export const useFetchLoadStaffBoardPosts = ({ sectionId }: Props) => {
  return useQuery<StaffBoardPostInterface[], HTTPErrors>(
    cacheKeyOf(sectionId),
    async () => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/staff_board_posts`,
      );

      if (response.ok) {
        const json = await response.json();
        return json.staffBoardPosts.data;
      }
      throw await createError(response);
    },
  );
};

const cacheKeyOf = (sectionId: string) => [
  `/api/v1/sections/${sectionId}/staff_board_posts`,
];
