import { useFormik } from "formik";
import * as yup from "yup";
import { useMutateConsentAgreement } from "./useMutateConsentAgreement";
import { useDispatch, useSelector } from "react-redux";
import AppStateInterface from "../../interfaces/AppStateInterface";
import AuthorizationStateInterface from "../../interfaces/AuthorizationStateInterface";
import { updateUserConsentAgreedSucess } from "../../actions/authorization/userInfo";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { dispatchSignOut } from "../../actions/common/session";

const initialValues = {
  consentAgreement: false,
};

const validationSchema = yup.object().shape({
  consentAgreement: yup
    .bool()
    .oneOf([true], "利用規約・プライバシーポリシーに同意してください"),
});

export const useConsentAgreement = () => {
  const currentOperator = useSelector<
    AppStateInterface,
    AuthorizationStateInterface["currentOperator"]
  >((state) => state.session.currentOperator);

  // ローディング中にモーダルが出ないように、currentOperatorが取得できてない時はfalseにする
  const isConsentAgreed = currentOperator
    ? currentOperator.isConsentAgreed
    : false;

  const dispatch = useDispatch();

  const { showErrorMessage } = useFlashMessage();

  const signOut = () => {
    dispatch(dispatchSignOut());
  };

  const { mutate, isLoading } = useMutateConsentAgreement({
    onSuccess: () => {
      dispatch(updateUserConsentAgreedSucess());
    },
    onError: () => {
      showErrorMessage("データの更新に失敗しました");
    },
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      mutate();
    },
  });

  const checkboxProps = {
    onChange: () => {
      const { consentAgreement } = formik.values;
      formik.setFieldValue("consentAgreement", !consentAgreement);
    },
    name: "consentAgreement",
    value: "consentAgreement",
    ...(formik.values.consentAgreement ? { checked: true } : {}),
  };
  return {
    checkboxProps,
    handleSubmit: formik.handleSubmit,
    errors: formik.errors.consentAgreement,
    isLoading,
    isOpen: !isConsentAgreed,
    signOut,
  };
};
