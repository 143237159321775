import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import { createError, HTTPErrors } from "../../errors";
import { Examination } from "../../domains/Examination";

type Props = {
  enabled: boolean;
  organizerId: string | null;
  classificationId: string | null;
};
export const useFetchExaminations = ({
  enabled,
  organizerId,
  classificationId,
}: Props) => {
  const result = useQuery<readonly Examination[], HTTPErrors>(
    [`examinations`, organizerId, classificationId],
    async () => {
      const res = await ApiClient.get(
        `/api/v1/examination_organizers/${organizerId}/examination_classifications/${classificationId}/examinations`,
      );

      if (res.ok) {
        const json = (await res.json()) as {
          examinations: Examination[];
        };
        return json.examinations;
      }
      const error = await createError(res);
      throw error;
    },
    { enabled, retry: false },
  );

  return result;
};
