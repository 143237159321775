import SectionInterface from "../../interfaces/SectionInterface";
import { createError, HTTPErrors } from "../../errors";
import { useMutation } from "@tanstack/react-query";
import api from "../../api";

export type UsePostDrillRegistrationCodesProps = {
  section: SectionInterface;
  onSuccess: () => void;
};
export const usePostDrillRegistrationCodes = ({
  section,
  onSuccess,
}: UsePostDrillRegistrationCodesProps) => {
  const baseURL = `/api/v1/sections/${section.id}/drill_learning_materials`;

  return useMutation<void, HTTPErrors, readonly string[]>(
    async (codes) => {
      const res = await api.post(baseURL, { drill_registration_codes: codes });
      if (res.status === 204) {
        return;
      } else {
        throw await createError(res);
      }
    },
    {
      onSuccess,
    },
  );
};
