import { useMutation, useQueryClient } from "@tanstack/react-query";
import SectionInterface from "../../../interfaces/SectionInterface";
import ApiClient from "../../../api";
import { createError, HTTPErrors } from "../../../errors";
import { sectionLearningMaterialQueryKey } from "./useFetchSectionLearningMaterial";
import { sectionLearningMaterialsQueryKey } from "../../../hooks/http/useFetchSectionLearningMaterials";
import { HTTPMethod } from "../../../api";

type Props = {
  section: SectionInterface;
  learningMaterialId?: string;
};
export type SectionLearningMaterialParams = {
  name: string;
  tag_ids: string[];
  file?: File;
};
export const useMutateSectionLearningMaterial = ({
  section,
  learningMaterialId,
}: Props) => {
  const client = useQueryClient();
  const method: HTTPMethod = learningMaterialId ? "PATCH" : "POST";
  const mutation = useMutation<void, HTTPErrors, SectionLearningMaterialParams>(
    async (params: SectionLearningMaterialParams) => {
      const response = await request({
        sectionId: section.id,
        learningMaterialId,
        method,
        params,
      });

      if (!response.ok) {
        throw await createError(response);
      }
    },
    {
      onSuccess: () => {
        // 一覧APIと詳細APIのキャッシュを無効化
        client.invalidateQueries(
          sectionLearningMaterialsQueryKey({
            sectionId: section.id,
          }),
        );
        if (learningMaterialId) {
          client.invalidateQueries(
            sectionLearningMaterialQueryKey({
              sectionId: section.id,
              learningMaterialId,
            }),
          );
        }
      },
    },
  );

  return {
    ...mutation,
    method,
  };
};

type RequestParams = {
  sectionId: string;
  learningMaterialId?: string;
  method: "PATCH" | "POST";
  params: SectionLearningMaterialParams;
};
const request = ({
  sectionId,
  learningMaterialId,
  method,
  params,
}: RequestParams) => {
  const path =
    method === "PATCH"
      ? `/api/v1/sections/${sectionId}/learning_materials/${learningMaterialId}`
      : `/api/v1/sections/${sectionId}/learning_materials`;

  return ApiClient.sendFormData(path, method, params);
};
