import {
  useInfiniteQuery,
  useQueryClient,
  useMutation,
  InfiniteData,
} from "@tanstack/react-query";
import api from "../../api";
import ApiResponse from "../../interfaces/ApiResponse";
import { ClassroomList } from "../../domains/Classroom";
import { ArchiveStatus, invertArchiveStatus } from "../../domains/Attendance";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";

type UseFetchClassroomProps = {
  sectionId: string;
  archiveStatus?: ArchiveStatus;
  shouldGetAllRecords?: boolean;
  refetchOnWindowFocus?: boolean;
};

export type ResponseData = ApiResponse<ClassroomList>;
export const useFetchClassrooms = ({
  sectionId,
  archiveStatus = ArchiveStatus.active,
  shouldGetAllRecords = false,
  refetchOnWindowFocus = true,
}: UseFetchClassroomProps) => {
  const result = useInfiniteQuery<ResponseData, HTTPErrors>(
    cacheKeyOf(sectionId, archiveStatus),
    async ({ pageParam = 1 }) => {
      const response = await api.interruptGet(`${baseURLOf(sectionId)}`, {
        query: {
          status: archiveStatus,
          page: pageParam,
          per: shouldGetAllRecords ? 99999 : 20,
        },
      });
      if (response.ok) {
        const json = await response.json();
        return json.classrooms as ResponseData;
      }
      throw await createError(response);
    },
    {
      refetchOnWindowFocus,
      getNextPageParam,
    },
  );
  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data };
};

export const cacheKeyOf = (sectionId: string, status: ArchiveStatus) => [
  sectionId,
  "classrooms",
  status,
];

export const baseURLOf = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/classrooms`;

type UseMutationClasroomProps = {
  sectionId: string;
  onSuccess: () => void;
  onError: () => void;
};

type ArchiveClassroomParams = {
  classroomId: string;
  archiveStatus: ArchiveStatus;
};

export const useUpdateClassroomArchiveStatus = ({
  sectionId,
  onError,
  onSuccess,
}: UseMutationClasroomProps) => {
  const client = useQueryClient();
  return useMutation<void, HTTPErrors, ArchiveClassroomParams>(
    async ({ classroomId, archiveStatus }) => {
      const res = await api.patch(
        `/api/v1/sections/${sectionId}/classrooms/${classroomId}`,
        { status: archiveStatus },
      );
      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    {
      onSuccess(_, param) {
        const cacheKey = cacheKeyOf(
          sectionId,
          invertArchiveStatus(param.archiveStatus),
        );
        const cache = client.getQueryData<InfiniteData<ResponseData>>(cacheKey);
        if (cache) {
          client.setQueryData<InfiniteData<ResponseData>>(cacheKey, {
            ...cache,
            pages: cache.pages.map((resData) => ({
              ...resData,
              data: resData.data.filter(
                (classroom) => classroom.id !== param.classroomId,
              ),
            })),
          });
        }
        onSuccess();
      },
      onError,
    },
  );
};
