import { useInfiniteQuery } from "@tanstack/react-query";
import ApiClient from "../../../api";
import { HTTPErrors, createError } from "../../../errors";
import ApiResponse from "../../../interfaces/ApiResponse";
import SectionInterface from "../../../interfaces/SectionInterface";
import { SectionOperatorInvitationInterface } from "../../../interfaces/SectionOperatorInvitationInterface";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";

type ResponseData = {
  sections: ApiResponse<SectionInterface[]>;
  sectionOperatorInvitations: SectionOperatorInvitationInterface[];
};

export const useFetchSections = () => {
  const baseURL = "/api/v1/sections";
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useInfiniteQuery<ResponseData, HTTPErrors>(
    cachekeyOf(),
    async ({ pageParam = 1 }) => {
      const response = await ApiClient.get(baseURL, {
        query: {
          page: pageParam,
        },
      });
      if (response.ok) {
        const json = await response.json();
        return {
          sections: json.sections,
          sectionOperatorInvitations: json.sectionOperatorInvitations,
        };
      }
      throw await createError(response);
    },
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPages } = lastPage.sections.meta;
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      refetchOnWindowFocus: false,
    },
  );

  useOnMainScrollAreaScroll(() => {
    if (!isLoading && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);

  return {
    sections: data?.pages.flatMap((page) => page.sections.data) ?? [],
    sectionOperatorInvitations:
      data?.pages.flatMap((page) => page.sectionOperatorInvitations) ?? [],
    isLoading,
    isFetchingNextPage,
    error,
  };
};

const cachekeyOf = () => ["/api/v1/sections"];
