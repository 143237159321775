import { useInfiniteQuery } from "@tanstack/react-query";
import {
  StudentProgressList,
  StudentProgress,
} from "../../../domains/StudentProgress";
import ApiClient from "../../../api";
import { HTTP_ERROR_MESSAGE } from "../../../reducers/index";
import { toDateString } from "../../../helpers/TimeHelper";
import SectionInterface from "../../../interfaces/SectionInterface";
import { TermType } from "../../../domains/QueryDateRange";
import { createError, HTTPErrors } from "../../../errors";
import { stringify } from "query-string";

export type UseFetchStudySchedulesProps = {
  section: SectionInterface;
  filterParams: Record<string, any>;
  dateParams: ApiDateParams;
  hasPlanning: boolean;
  enabledFetch: boolean;
};

export type ApiDateParams = {
  from: Date;
  to: Date;
  term: TermType;
};

type MetaData = {
  since: string | null;
  till: string | null;
};
type ResponseData = {
  data: StudentProgressList;
  meta: MetaData;
};

export const useFetchSectionStudySchedules = (
  props: UseFetchStudySchedulesProps,
) => {
  const sectionId = props.section.id;
  const queryString = stringify(
    {
      ...props.filterParams,
      from: toDateString(props.dateParams.from),
      to: toDateString(props.dateParams.to),
      term: props.dateParams.term,
      has_planning: props.hasPlanning,
    },
    { arrayFormat: "bracket" },
  );

  return useInfiniteQuery<ResponseData, HTTPErrors>(
    [`section/${sectionId}/study_schedules`, queryString],
    async ({ pageParam = null }) => {
      const res = await ApiClient.interruptGet(
        `/api/v1/sections/${sectionId}/study_schedules?${queryString}&since=${
          pageParam ?? ""
        }`,
      );
      if (res.ok) {
        const json = await res.json();
        if (
          !json.studentProgresses ||
          !Array.isArray(json.studentProgresses.data)
        ) {
          throw HTTP_ERROR_MESSAGE;
        }

        return {
          meta: json.studentProgresses.meta as MetaData,
          data: json.studentProgresses.data.map(
            (studentProgress: Record<string, any>) => {
              return studentProgress as StudentProgress;
            },
          ),
        };
      } else {
        throw await createError(res);
      }
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.meta.till;
      },
      refetchOnWindowFocus: false,
      enabled: props.enabledFetch,
    },
  );
};
