import { useInfiniteQuery } from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../../errors";
import { boronClient } from "../../../api";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { paths } from "../../../lib/api/v1";

type Response =
  paths["/api/v1/sections/{section_id}/import_histories"]["get"]["responses"]["200"]["content"]["application/json"]["importHistories"];

export const useFetchImportHistories = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useInfiniteQuery<Response, HTTPErrors>({
    queryKey: ["importHistories", sectionId],
    queryFn: async ({ pageParam = 1 }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/import_histories",
        {
          params: {
            path: { section_id: sectionId },
            query: { page: pageParam },
          },
        },
      );
      if (response.ok && data) {
        return data.importHistories;
      }
      throw await createError(response);
    },
    getNextPageParam,
  });

  const importHistories = data?.pages.flatMap((page) => page.data) || [];

  return {
    importHistories,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  };
};
