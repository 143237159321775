import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { createError } from "../../errors";
import ApiClient from "../../api";

type Props = {
  sectionId: string;
  pathname: string;
  enabled: boolean;
  onSuccess: UseQueryOptions<ResponseBody>["onSuccess"];
};
type ResponseBody = { count: number };
export const useFetchBotMessageNotificationUnseenCount = ({
  sectionId,
  pathname,
  enabled,
  onSuccess,
}: Props) => {
  const path = `/api/v1/sections/${sectionId}/bot_message_notifications/unseen_count`;
  return useQuery<ResponseBody, Error>(
    cacheKeyOf({ path, pathname }),
    async () => {
      const response = await ApiClient.interruptGet(path);
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    {
      enabled,
      onSuccess,
    },
  );
};

export const useFetchGuardianMessageNotificationUnseenCount = ({
  sectionId,
  pathname,
  enabled,
  onSuccess,
}: Props) => {
  const path = `/api/v1/sections/${sectionId}/guardian_messages/unseen_count`;
  return useQuery<ResponseBody, Error>(
    cacheKeyOf({ path, pathname }),
    async () => {
      const response = await ApiClient.interruptGet(path);
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    {
      enabled,
      onSuccess,
    },
  );
};

export const useFetchMessageNotificationUnseenCount = ({
  sectionId,
  pathname,
  enabled,
  onSuccess,
}: Props) => {
  const path = `/api/v1/sections/${sectionId}/direct_messages/unseen_count`;
  return useQuery<ResponseBody, Error>(
    cacheKeyOf({ path, pathname }),
    async () => {
      const response = await ApiClient.interruptGet(path);
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    {
      enabled,
      onSuccess,
    },
  );
};

export const useFetchCommentNotificationUnreadCount = ({
  sectionId,
  pathname,
  enabled,
  onSuccess,
}: Props) => {
  const path = `/api/v1/sections/${sectionId}/study_records/comments/unread_count`;
  return useQuery<ResponseBody, Error>(
    cacheKeyOf({ path, pathname }),
    async () => {
      const response = await ApiClient.interruptGet(path);
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    {
      enabled,
      onSuccess,
    },
  );
};

const cacheKeyOf = ({ path, pathname }: { path: string; pathname: string }) => [
  path,
  pathname,
];
