import StudentInterface from "../../../interfaces/StudentInterface";
import LinkButton from "../../../components/atoms/LinkButton";
import QRCodeModal from "./QRCodeModal";
import Settings from "../../../helpers/Settings";
import Icon from "../../../components/atoms/Icon";
import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../../api";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

const LINE_CODE_USAGE_URL =
  "https://www.dropbox.com/s/uakpsc4jcmo2pp9/LINE%E9%80%A3%E6%90%BA%E3%81%AE%E5%88%A9%E7%94%A8%E6%96%B9%E6%B3%95.pdf";

export const LineCode = ({
  student,
  updateStudent,
}: {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
}) => {
  const { mutate, isLoading } = useIssueLineCode({
    studentId: student.id,
    updateStudent,
  });
  const handleIssueLineCode = () => {
    if (!isLoading) {
      mutate({ reissue: false });
    }
  };

  const handleReissueLineCode = () => {
    if (
      !isLoading &&
      window.confirm(
        `保護者LINE連携コードを再発行すると、現在連携しているLINEユーザーとの連携が解除され、メッセージの送受信ができなくなります。\n本当に「${student.fullName}」さんの保護者とのLINE連携を停止して、新規連携コードを発行してもよろしいですか？`,
      )
    ) {
      mutate({ reissue: true });
    }
  };

  switch (student.lineConnectionStatus) {
    case "unissued":
      if (student.status === "inviting" || student.status === "active") {
        return <LinkButton onClick={handleIssueLineCode}>発行</LinkButton>;
      } else {
        return <>-</>;
      }
    case "waiting":
      if (student.lineCode === null) {
        return null;
      }

      return (
        <QRCodeModal
          title={`${student.fullName}さんの保護者用LINE連携QRコード`}
          qrcodeString={`https://line.me/R/oaMessage/${
            Settings.LINE_OFFICIAL_ACCOUNT_ID
          }/?${encodeURI(student.lineCode)}`}
          beforePrintButton={<div>連携コード：{student.lineCode}</div>}
          afterPrintButton={
            <a
              href={LINE_CODE_USAGE_URL}
              className="flex flex-nowrap items-center fill-blue-400 text-blue-400"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="icon-attention" />
              保護者LINE連携の利用方法はこちら
            </a>
          }
        />
      );
    case "connected":
      return <LinkButton onClick={handleReissueLineCode}>再発行</LinkButton>;
  }
};

const useIssueLineCode = ({
  studentId,
  updateStudent,
}: {
  studentId: string;
  updateStudent: (student: StudentInterface) => void;
}) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  return useMutation<void, void, { reissue: boolean }>({
    mutationFn: async ({ reissue }) => {
      const { response, data } = reissue
        ? await boronClient.PATCH(
            "/api/v1/students/{student_id}/line_connection_code",
            { params: { path: { student_id: studentId } } },
          )
        : await boronClient.POST(
            "/api/v1/students/{student_id}/line_connection_code",
            { params: { path: { student_id: studentId } } },
          );
      if (response.ok && data) {
        updateStudent(data.student);
        const message = reissue
          ? "LINE連携コードを再発行しました"
          : "LINE連携コードを発行しました";
        showSuccessMessage(message);
        return;
      }
      const errorMessage = reissue
        ? "LINE連携コードを再発行できませんでした"
        : "LINE連携コードを発行できませんでした";
      showErrorMessage(errorMessage);
    },
  });
};
