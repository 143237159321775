import { useMutation } from "@tanstack/react-query";
import api from "../../api";
import { HTTPErrors, createError } from "../../errors";

type Props = {
  sectionId: string;
};
type Params = {
  studentId: string;
  originLectureSessionId: string;
  destinationLectureSessionId: string;
};

export const useMutateLectureTransfer = ({ sectionId }: Props) => {
  return useMutation<void, HTTPErrors, Params>(
    async ({
      studentId,
      originLectureSessionId,
      destinationLectureSessionId,
    }: Params) => {
      const response = await api.post(
        `/api/v1/sections/${sectionId}/lecture_sessions/${originLectureSessionId}/students/${studentId}/lecture_transfers`,
        {
          transfer_lecture_session_id: destinationLectureSessionId,
        },
      );
      if (response.ok) {
        return;
      }
      throw await createError(response);
    },
  );
};
