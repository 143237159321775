import { useQuery } from "@tanstack/react-query";
import {
  fromJson,
  LearningMaterialProgress,
} from "../../../domains/LearningMaterialProgress";
import ApiClient from "../../../api";
import { HTTP_ERROR_MESSAGE } from "../../../reducers/index";
import { toDateString } from "../../../helpers/TimeHelper";
import { createError, HTTPErrors } from "../../../errors";

export type FetchStudySchedulesParams = {
  studentId: string;
  from: Date;
  to: Date;
};

type CacheKeys = {
  studentId: string;
  from: string;
  to: string;
};

const buildCacheKeys = ({ studentId, from, to }: CacheKeys): string[] => [
  `students/${studentId}/study_schedules`,
  from,
  to,
];

export const useFetchStudySchedules = (params: FetchStudySchedulesParams) => {
  const { studentId } = params;
  const from = toDateString(params.from);
  const to = toDateString(params.to);

  const { isLoading, data, isError, error, refetch } = useQuery<
    LearningMaterialProgress[] | undefined,
    HTTPErrors
  >(buildCacheKeys({ studentId, from, to }), async () => {
    const res = await ApiClient.interruptGet(
      `/api/v1/students/${studentId}/study_schedules`,
      {
        query: {
          from,
          to,
        },
      },
    );

    if (res.ok) {
      const json = await res.json();
      if (!Array.isArray(json.progresses)) {
        console.error("The response must return an array of progresses");
        throw HTTP_ERROR_MESSAGE;
      }
      return json.progresses.map((progress: unknown) => fromJson(progress));
    } else {
      throw await createError(res);
    }
  });

  return { isLoading, data, isError, error, refetch };
};
