import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import Api from "../../../../api";
import StudentTag from "../../../../domains/StudentTag";
import { createError, HTTPErrors } from "../../../../errors";
import { cacheKeyOf as courseDetailCacheKeyOf } from "../../../../hooks/http/useContentCoursesApi";
import { cacheKeyOf as courseStudentsCacheKeyOf } from "../SectionsSettingsContentCourseStudents/useFetchContentCourseStudents";

type MutationOptionType = UseMutationOptions<
  string,
  HTTPErrors,
  MutateDistributeTagParams
>;

// TODO: できればdomainの型で渡したいが・・・
type UseMutateDistributeTag = {
  sectionId: string;
  contentCourseId: string;
  onError: MutationOptionType["onError"];
  onSuccess: () => void;
};

type MutateDistributeTagParams = {
  tag: StudentTag | null;
};

export const useRegisterContentCourseTag = ({
  contentCourseId,
  sectionId,
  onError,
  onSuccess,
}: UseMutateDistributeTag) => {
  const client = useQueryClient();
  return useMutation<string, HTTPErrors, MutateDistributeTagParams>(
    async ({ tag }: MutateDistributeTagParams) => {
      const res = await Api.patch(
        `/api/v1/sections/${sectionId}/contents/courses/${contentCourseId}/tag`,
        {
          tag_id: tag ? tag.id : null,
        },
      );
      if (res.status === 204) {
        return "";
      }
      throw await createError(res);
    },
    {
      onSuccess: () => {
        // キーを配列で渡せるっぽいんだけど、配列で渡すとうまく更新されない・・・
        // TODO: ↑更新されるか確認しておく
        client.invalidateQueries(
          courseStudentsCacheKeyOf(sectionId, contentCourseId),
        );
        client.invalidateQueries(
          courseDetailCacheKeyOf(sectionId, contentCourseId),
        );
        onSuccess?.();
      },
      onError,
    },
  );
};
