import ApiClient from "../../../api";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContentCourse } from "../../../domains/ContentCourse";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

type UseDeleteCourseHook = {
  onDelete: (course: ContentCourse) => void;
  isDeleting: boolean;
};
export const useDeleteCourse = (sectionId: string): UseDeleteCourseHook => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const client = useQueryClient();
  const { mutate, isLoading: isDeleting } = useMutation(
    async (course: ContentCourse) => {
      const request = buildDeleteRequest(sectionId, course);
      if (confirm(request.messages.onConfirm)) {
        const response = await ApiClient.delete(request.url);

        if (response.ok) {
          showSuccessMessage(request.messages.onSuccess);
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      }
    },
    {
      onSuccess: () =>
        client.invalidateQueries([`sections/${sectionId}/contents/courses`]),
    },
  );

  const onDelete = useCallback(
    (course: ContentCourse) => mutate(course),
    [mutate],
  );

  return {
    onDelete,
    isDeleting,
  };
};

type DeleteRequest = {
  messages: {
    onConfirm: string;
    onSuccess: string;
  };
  url: string;
};
const buildDeleteRequest = (
  sectionId: string,
  course: ContentCourse,
): DeleteRequest => {
  if (course.isSharedCourse) {
    return {
      messages: {
        onConfirm:
          ` コース「${course.name}」を共有解除してもよろしいですか？` +
          `共有解除すると、生徒へ送信したコースURLが無効になります。`,
        onSuccess: `コースを共有解除しました。`,
      },
      url: `/api/v1/sections/${sectionId}/contents/courses/${course.id}/share`,
    };
  } else {
    return {
      messages: {
        onConfirm:
          `コース「${course.name}」を削除してもよろしいですか？` +
          `削除すると、生徒へ送信したコースURLが無効になります。` +
          `また、他教室でこのコース登録が行われている場合、他教室上のコースも消えますのでご注意ください。`,
        onSuccess: `コースを削除しました。`,
      },
      url: `/api/v1/sections/${sectionId}/contents/courses/${course.id}`,
    };
  }
};
