import { useInfiniteQuery } from "@tanstack/react-query";
import SectionInterface from "../../../interfaces/SectionInterface";
import ApiResponse from "../../../interfaces/ApiResponse";
import { LectureStudentAnalytics } from "../../../domains/LectureStudentAnalytics";
import { createError, HTTPErrors } from "../../../errors";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import api from "../../../api";
import { Lecture } from "../../../domains/Lecture";
import { fromJson } from "../../../domain-adapters/LectureStudentAnalytics";
import { SearchCondition } from "../types";
import { OrderType } from "../../../domains/LectureStudentAnalytics";

type ResponseData = ApiResponse<LectureStudentAnalytics>;

type Props = {
  section: SectionInterface;
  lecture: Lecture;
  searchCondition: SearchCondition;
};
export const useFetchAnalyticsLectureStudent = ({
  lecture,
  section,
  searchCondition,
}: Props) => {
  return useInfiniteQuery<ResponseData, HTTPErrors>(
    cachekeyOf({ section, searchCondition, lecture }),
    async ({ pageParam = 1 }) => {
      const res = await api.interruptGet(urlOf({ section, lecture }), {
        query: {
          page: pageParam,
          lecture_session_id: searchCondition.lectureSessionId,
          pagination_dir: searchCondition.paginationDir,
          order: convertOrderType(searchCondition.order),
          order_dir: searchCondition.orderDir,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return {
          meta: json.lectureStudentsAnalyticsTable.meta,
          data: fromJson(json.lectureStudentsAnalyticsTable.data),
        };
      }
      throw await createError(res);
    },
    {
      getNextPageParam,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};

const cachekeyOf = ({ section, lecture, searchCondition }: Props) => [
  "analyticsLectureStudent",
  section.id,
  lecture.id,
  searchCondition,
];

const urlOf = ({ section, lecture }: Pick<Props, "section" | "lecture">) =>
  `/api/v1/sections/${section.id}/analytics/lectures/${lecture.id}/students`;

const convertOrderType = (order?: OrderType) => {
  if (!order) return null;
  return order === "totalScore"
    ? "total_score"
    : "with_transferred_total_score";
};
