import { useNavigate } from "react-router";
import { useFetchContent, useMutateContentFile } from "./useContentFileApi";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

export type UseSectionSettingsEditContentFilesProps = {
  sectionId: string;
  contentId: string;
  onNotFoundContent: () => void;
  onForbiddenContent: () => void;
  onUnknownError: () => void;
};

export const useSectionSettingsEditContentFiles = ({
  sectionId,
  contentId,
  onNotFoundContent,
  onForbiddenContent,
  onUnknownError,
}: UseSectionSettingsEditContentFilesProps) => {
  const navigate = useNavigate();

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const fetchContent = useFetchContent({
    sectionId,
    contentId,
    onError: (err) => {
      switch (err) {
        case "403ContentError":
          onForbiddenContent();
          break;
        case "404ContentError":
          onNotFoundContent();
          break;
        default:
          onUnknownError();
          break;
      }
    },
  });

  const { mutate, isMutating } = useMutateContentFile({
    sectionId,
    onSuccess: () => {
      showSuccessMessage("ファイルの編集を完了しました");
      navigate(`/sections/${sectionId}/settings/contents`);
    },
    onError: () => {
      showErrorMessage(
        "ファイルの編集に失敗しました。しばらく間を開けてから再度お試しください。",
      );
    },
  });

  return {
    isLoading: fetchContent.isLoading,
    content: fetchContent.data,
    mutate,
    isMutating,
  };
};
