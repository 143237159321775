import {
  LearningMaterialTagList,
  LearningMaterialTagType,
} from "../../domains/LearningMaterialTag";
import SectionInterface from "../../interfaces/SectionInterface";
import {
  useInfiniteQuery,
  useQueryClient,
  InfiniteData,
} from "@tanstack/react-query";
import api from "../../api";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";
import {
  LearningMaterialTag,
  LearningMaterialTagWithDeletable,
} from "../../domains/LearningMaterialTag";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { useMutation } from "@tanstack/react-query";
import ApiClient from "../../api";

export type NarrowingConditions = LearningMaterialTagType | "all";

const makeNarrowingConditionParam = (condition: NarrowingConditions) => {
  switch (condition) {
    case "default":
      return ["default"];
    case "custom":
      return ["custom"];
    case "all":
    default:
      return ["custom", "default"];
  }
};

type Props = {
  section: SectionInterface;
  narrowingCondition: NarrowingConditions;
  shouldGetAllRecords?: boolean;
};

export const useFetchLearningMaterialTags = (props: Props) => {
  const path = baseUrl(props.section.id);

  const result = useInfiniteQuery<
    ApiResponse<ReadonlyArray<LearningMaterialTag>>,
    HTTPErrors
  >(
    cacheKeyOf(props.section.id, props.narrowingCondition),
    async ({ pageParam = 1 }) => {
      const res = await api.interruptGet(`/api/v1${path}`, {
        query: {
          tag_types: makeNarrowingConditionParam(props.narrowingCondition),
          page: pageParam,
          no_pagination: props.shouldGetAllRecords,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return json.learningMaterialTags;
      }
      throw await createError(res);
    },
    {
      getNextPageParam,
    },
  );

  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data };
};

type UseAssociateLearningMaterialTagProps = {
  sectionId: string;
  onSuccess?: () => void;
  onError?: () => void;
};

type AssociateLearningMaterialTagRequestParam = {
  learningMaterialCode: string;
  learningMaterialTags: LearningMaterialTagList;
};

export const useAssociateLearningMaterialTag = ({
  sectionId,
  onSuccess,
  onError,
}: UseAssociateLearningMaterialTagProps) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { mutate, isLoading, isError } = useMutation<
    void,
    Error,
    AssociateLearningMaterialTagRequestParam
  >(
    async ({ learningMaterialCode, learningMaterialTags }) => {
      const response = await ApiClient.put(
        `/api/v1/sections/${sectionId}/management_learning_materials/${learningMaterialCode}/tags`,
        {
          learning_material_tag_ids: learningMaterialTags.map((tag) => tag.id),
        },
      );

      if (response.ok) {
        showSuccessMessage("教材タグを設定しました");
      } else {
        showErrorMessage("教材タグを設定できませんでした");
      }
    },
    {
      onSuccess,
      onError,
    },
  );
  return {
    mutate,
    isLoading,
    isError,
  };
};

export const useLearningMaterialTagsCache = (
  sectionId: string,
  narrowingCondition: NarrowingConditions = "all",
) => {
  const client = useQueryClient();
  const result = client.getQueryData<
    InfiniteData<ApiResponse<ReadonlyArray<LearningMaterialTagWithDeletable>>>
  >(cacheKeyOf(sectionId, narrowingCondition));
  return result?.pages.flatMap((page) => page.data);
};

export const cacheKeyOf = (
  sectionId: string,
  narrowingCondition: NarrowingConditions = "all",
) => [baseUrl(sectionId), narrowingCondition];

const baseUrl = (sectionId: string) =>
  `/sections/${sectionId}/learning_material_tags`;
