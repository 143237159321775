import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { paths } from "../../../../lib/api/v1";
import { createError, HTTPErrors } from "../../../../errors";
import { boronClient } from "../../../../api";
import {
  DirectMessage,
  MessageInterface,
} from "../../../../interfaces/MessageInterface";
import { BotMessagePointer } from "../../../../interfaces/BotMessageInterface";
import { useEffect, useRef } from "react";

type MessageResponse =
  paths["/api/v1/students/{student_id}/direct_messages"]["get"]["responses"]["200"]["content"]["application/json"]["messages"];
type BotMessageResponse =
  paths["/api/v1/students/{student_id}/bot_messages"]["get"]["responses"]["200"]["content"]["application/json"]["botMessages"];
type Messages = { messages: MessageResponse; botMessages: BotMessageResponse };

// DirectMessageとBotMessageは別APIから取得するため、それぞれのAPI結果を結合して返す
export const useFetchMessages = ({ studentId }: { studentId: string }) => {
  const queryClient = useQueryClient();
  const previousStudentId = useRef<string | null>(null);

  // BotMessageを一括でロードする仕様上、大量にキャッシュされることがある
  // スレッドの切り替え時にreact-queryのキャッシュを復元する時に画面が固まるため、
  // スレッドの切り替え時 = studentIdの変更時にキャッシュを削除する
  useEffect(() => {
    if (previousStudentId.current) {
      queryClient.removeQueries(buildKey(previousStudentId.current));
    }
    previousStudentId.current = studentId;
  }, [studentId]);

  const { data, error, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery<Messages, HTTPErrors>(
      buildKey(studentId),
      async ({ pageParam, queryKey }) => {
        // DirectMessageの取得
        const { response, data } = await fetchDirectMessages({
          studentId,
          since: pageParam,
        });

        if (response.ok && data) {
          // pageParamがない場合は最初のページのロードのため、キャッシュは存在しないものとして扱う
          const messagesCache = pageParam
            ? queryClient.getQueryData<InfiniteData<Messages>>(queryKey)
            : undefined;
          const messages = messagesCache ? sortMessages(messagesCache) : [];

          // BotMessageの取得
          // 取得したデータとキャッシュされたデータから、BotMessageの取得範囲を決定する
          const { response: botMessageResponse, data: botMessageData } =
            await fetchBotMessages({
              studentId,
              query: botMessagePointer(data.messages.data, messages),
            });
          if (botMessageResponse.ok && botMessageData) {
            // 取得したDirectMessageとBotMessageを結合する
            return {
              messages: data.messages,
              botMessages: botMessageData.botMessages,
            };
          }
        }
        throw await createError(response);
      },
      {
        getNextPageParam: (lastPage) => {
          // 最後のページが空の場合は次のページがないと判断する
          if (lastPage.messages.data.length === 0) {
            return undefined;
          }
          return lastPage.messages.meta.till;
        },
        refetchOnWindowFocus: false, // reduxだったときにはrefetchしていなかったので合わせる
      },
    );

  const messages = data ? sortMessages(data) : [];

  return { isLoading, error, isFetchingNextPage, fetchNextPage, messages };
};

const fetchDirectMessages = async ({
  studentId,
  since,
}: {
  studentId: string;
  since: string;
}) => {
  return await boronClient.GET(
    "/api/v1/students/{student_id}/direct_messages",
    {
      params: {
        path: {
          student_id: studentId,
        },
        query: {
          since,
        },
      },
    },
  );
};

const fetchBotMessages = async ({
  studentId,
  query,
}: {
  studentId: string;
  query: {
    from?: string;
    to?: string;
  };
}) => {
  return await boronClient.GET("/api/v1/students/{student_id}/bot_messages", {
    params: {
      path: {
        student_id: studentId,
      },
      query,
    },
  });
};

const botMessagePointer = (
  newMessages: DirectMessage[],
  cachedMessages: MessageInterface[],
): BotMessagePointer => {
  if (cachedMessages.length === 0) {
    // スレッドメッセージの最初のロードの時
    return newMessages.length < 20
      ? // 新規個別メッセージが20件未満の場合は、最古から最新までのBotメッセージをすべて取得する
        { from: new Date(0).toISOString(), to: new Date().toISOString() }
      : // 新規個別メッセージが20件以上の場合は、最新の個別メッセージの日時から最新までのBotメッセージをすべて取得する
        {
          from: newMessages[newMessages.length - 1].sentAt,
          to: new Date().toISOString(),
        };
  } else {
    // スレッドメッセージの追加ロードの時
    switch (newMessages.length) {
      // 新規個別メッセージがない場合は、キャッシュされたメッセージの最後の日時から最古までのBotメッセージをすべて取得する
      case 0:
        return {
          to: cachedMessages[cachedMessages.length - 1].sentAt,
        };
      // 新規個別メッセージが1件の場合は、個別メッセージの日時から最古までのBotメッセージをすべて取得する
      case 1:
        return {
          to: newMessages[0].sentAt,
        };
      // 新規個別メッセージが2件以上の場合は、取得した個別メッセージの最新から最古の日時に含まれるBotメッセージを取得する
      default:
        return {
          from: newMessages[newMessages.length - 1].sentAt,
          to: newMessages[0].sentAt,
        };
    }
  }
};

// directMessagesとbotMessagesのAPI結果から、directMessageとbotMessageをまとめて日時順に並べる
const sortMessages = (data: InfiniteData<Messages>): MessageInterface[] => {
  return data.pages
    .map((page) => [...page.messages.data, ...page.botMessages])
    .flat()
    .sort((a, b) => (a.sentAt > b.sentAt ? -1 : 1));
};

const buildKey = (studentId: string) => ["sectionStudentMessages", studentId];

export const useInsertDirectMessageCache = () => {
  const queryClient = useQueryClient();

  const insertDirectMessageCache = (
    studentId: string,
    directMessage: Messages["messages"]["data"][0],
  ) => {
    queryClient.setQueriesData<InfiniteData<Messages>>(
      buildKey(studentId),
      (oldData) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          pages: [
            {
              ...oldData.pages[0],
              messages: {
                ...oldData.pages[0].messages,
                data: [directMessage, ...oldData.pages[0].messages.data],
              },
            },
            ...oldData.pages.slice(1),
          ],
        };
      },
    );
  };
  return { insertDirectMessageCache };
};

export const useUpdateDirectMessageCache = () => {
  const queryClient = useQueryClient();

  const updateDirectMessageCache = (
    studentId: string,
    directMessage: Partial<Messages["messages"]["data"][0]>,
  ) => {
    queryClient.setQueriesData<InfiniteData<Messages>>(
      buildKey(studentId),
      (oldData) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              messages: {
                ...page.messages,
                data: page.messages.data.map((m) =>
                  directMessage.id === m.id ? { ...m, ...directMessage } : m,
                ),
              },
            };
          }),
        };
      },
    );
  };
  return { updateDirectMessageCache };
};

export const useUpdateBotMessageCache = () => {
  const queryClient = useQueryClient();

  const updateBotMessageCache = (
    studentId: string,
    botMessage: Partial<Messages["botMessages"][0]>,
  ) => {
    queryClient.setQueriesData<InfiniteData<Messages>>(
      buildKey(studentId),
      (oldData) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              botMessages: page.botMessages.map((m) =>
                botMessage.id === m.id ? { ...m, ...botMessage } : m,
              ),
            };
          }),
        };
      },
    );
  };
  return { updateBotMessageCache };
};
