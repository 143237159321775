import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router";
import { Dispatch } from "redux";
import { createAction } from "redux-actions";

import ApiClient from "../../../api";
import { ImportHistoryInterface } from "../../../domains/ImportHistory";
import { showErrorFlashMessage } from "../../flashMessage";

const postImportOperationSending = createAction(
  "POST_IMPORT_OPERATION_SENDING",
);
const postImportOperationReceived = createAction(
  "POST_IMPORT_OPERATION_RECEIVED",
);
const postImportOperationError = createAction("POST_IMPORT_OPERATION_ERROR");

export const postImportOperation =
  (
    sectionId: string,
    operation: ImportHistoryInterface["operation"],
    csv: File | undefined,
    zip: File | undefined,
    navigate: NavigateFunction,
    setErrors: (errors: any) => void,
    setSubmitting: FormikHelpers<Record<string, unknown>>["setSubmitting"],
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(postImportOperationSending());
    try {
      const result = await ApiClient.sendFormData(
        `/api/v1/sections/${sectionId}/import`,
        "POST",
        {
          operation,
          csv,
          zip,
        },
      );
      const json = await result.json();
      if (result.ok) {
        navigate(
          `/sections/${sectionId}/settings/import_histories/${json.importHistory.id}`,
        );
        dispatch(postImportOperationReceived());
      } else {
        dispatch(showErrorFlashMessage("一括操作が実行できませんでした"));
        dispatch(postImportOperationError({ errors: json.errors }));
        setErrors(json.errors);
      }
    } catch (e) {
      dispatch(showErrorFlashMessage("一括操作が実行できませんでした"));
      dispatch(postImportOperationError());
    } finally {
      setSubmitting(false);
    }
  };
