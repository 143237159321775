import SectionInterface from "../../../interfaces/SectionInterface";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import {
  UnprocessableEntityError,
  HTTPErrors,
  createError,
  UnprocessableEntityErrorItem,
} from "../../../errors";
import { cacheKeyOf } from "../../../hooks/http/useLearningMaterialTagApi";

export type FormValue = {
  id?: string;
  name: string;
};

type UseMutateLearningMaterailTagProps = {
  section: SectionInterface;
  onSuccess: () => void;
  onError?: (error: HTTPErrors) => void;
};
export const useSaveLearningMaterialTag = ({
  section,
  onError,
  onSuccess,
}: UseMutateLearningMaterailTagProps) => {
  const baseUrl = `/api/v1/sections/${section.id}/learning_material_tags`;
  const client = useQueryClient();

  return useMutation<void, HTTPErrors, FormValue>(
    async (params) => {
      const res = params.id
        ? await api.patch(`${baseUrl}/${params.id}`, {
            learning_material_tag: { name: params.name },
          })
        : await api.post(baseUrl, {
            learning_material_tag: { name: params.name },
          });
      if (res.ok) {
        return;
      }
      // 重複エラー
      if (res.status === 422) {
        const json = await res.json();
        throw new UnprocessableEntityError(
          json.errors as UnprocessableEntityErrorItem[],
        );
      }
      throw createError(res);
    },
    {
      onSuccess() {
        client.invalidateQueries(cacheKeyOf(section.id));
        onSuccess();
      },
      onError(error) {
        onError?.(error);
      },
    },
  );
};

type DeleteForm = {
  id: string;
};

export const useDeleteLearningMaterialTag = ({
  section,
  onSuccess,
}: UseMutateLearningMaterailTagProps) => {
  const baseUrl = `/api/v1/sections/${section.id}/learning_material_tags`;
  const client = useQueryClient();
  return useMutation<void, Error, DeleteForm>(
    async (param) => {
      const res = await api.delete(`${baseUrl}/${param.id}`);
      if (res.ok) {
        return;
      }
    },
    {
      onSuccess() {
        client.invalidateQueries(cacheKeyOf(section.id));
        onSuccess();
      },
    },
  );
};
