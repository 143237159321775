import { useParams } from "react-router-dom";
import { useErrorDispatcher } from "../../../hooks/useErrorDispatcher";
import SectionInterface from "../../../interfaces/SectionInterface";
import { matchHttpError } from "../../../errors";
import { useContentCourseDetailQuery } from "../../../hooks/http/useContentCoursesApi";

type UseSectionsSettingsContentCourseDetail = {
  section: SectionInterface;
};

export const useSectionsSettingsContentCourseDetail = (
  props: UseSectionsSettingsContentCourseDetail,
) => {
  const { courseId = "" } = useParams<{ courseId: string }>();
  const { sendNotFound, sendForbidden } = useErrorDispatcher();
  const { course, isLoading } = useContentCourseDetailQuery({
    onQueryError: (error) =>
      matchHttpError(error, {
        caseForbiden: sendForbidden,
        caseNotFound: sendNotFound,
      }),
    sectionId: props.section.id,
    courseId,
  });

  return {
    course,
    isLoading,
  };
};
