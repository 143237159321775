import { useMutation } from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../../errors";
import ApiClient from "../../../api";

type Props = {
  studentId: string;
  httpMethod: "POST" | "PATCH";
};

// 削除する場合はid: stringとdestroy: trueが必要
type NewResultAttachment = { file: File };
type ResultAttachmentToBeDestroyed = {
  id: string;
  destroy: "true";
};
type NewExaminationResult = {
  examinationId: string;
  point: number | null;
  allocationOfMarks: number | null;
  deviation: number | null;
  examinedOn: string;
  subjectResults: {
    id: string;
    point: number | null;
    allocationOfMarks: number | null;
    deviation: number | null;
  }[];
  resultAttachments: (NewResultAttachment | ResultAttachmentToBeDestroyed)[];
};

export const useMutateExaminationResult = ({
  studentId,
  httpMethod,
}: Props) => {
  return useMutation<void, HTTPErrors, NewExaminationResult>(
    async (examinationResult) => {
      const res = await ApiClient.sendFormData(
        `/api/v1/students/${studentId}/examinations/${examinationResult.examinationId}/result`,
        httpMethod,
        {
          examined_on: examinationResult.examinedOn,
          point: examinationResult.point,
          allocation_of_marks: examinationResult.allocationOfMarks,
          deviation: examinationResult.deviation,
          subject_results: examinationResult.subjectResults.map(
            (subjectResult) => ({
              id: subjectResult.id,
              point: subjectResult.point,
              allocation_of_marks: subjectResult.allocationOfMarks,
              deviation: subjectResult.deviation,
            }),
          ),
          result_attachments: examinationResult.resultAttachments,
        },
      );
      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
  );
};
