import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import { createError, HTTPErrors } from "../../errors";
import { ExaminationResult } from "../../domains/ExaminationResult";

type Props = {
  studentId: string;
  examinationId: string | null;
  retry?: boolean;
};

export const useFetchStudentExaminationResult = ({
  studentId,
  examinationId,
  retry = true,
}: Props) => {
  return useQuery<ExaminationResult, HTTPErrors>(
    cacheKeyOf(studentId, examinationId),
    async () => {
      if (!examinationId) {
        throw new Error(
          "examinationId is required to fetch examination result",
        );
      }

      const response = await ApiClient.get(
        `/api/v1/students/${studentId}/examinations/${examinationId}/result`,
      );
      if (response.ok) {
        const json = (await response.json()) as {
          examinationResult: ExaminationResult;
        };
        return json.examinationResult;
      }
      throw await createError(response);
    },
    {
      enabled: Boolean(examinationId),
      retry,
    },
  );
};

const cacheKeyOf = (studentId: string, examinationId: string | null) => [
  `/students/${studentId}/examinations/${examinationId}/result`,
];
