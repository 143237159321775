import * as React from "react";
import styles from "./GuardianEmailFormModal.scss";
import StudentInterface from "../../../../interfaces/StudentInterface";
import { Link } from "react-router-dom";
import { Modal } from "../../../../components/atoms/Modal";
import { Text } from "../../../../components/general/Text";
import {
  useFetchGuardianEmails,
  useDeleteGuardianEmail,
} from "./useGuardianEmailsApi";
import Loader from "../../../../components/atoms/Loader";
import {
  translateGuardianEmailStatus,
  type GuardianEmail,
} from "../../../../domains/GuardianEmail";
import Icon from "../../../../components/atoms/Icon";
import LinkButton from "../../../../components/atoms/LinkButton";
import { Colors } from "../../../../helpers/styles/Colors";
import TimeHelper from "../../../../helpers/TimeHelper";
import { Flex } from "../../../../components/atoms/Flex";
import { Button } from "@studyplus/boron-ui";
import { useGuardianEmailModal } from "./useGuardianEmailModal";
import { GuardianEmailForm } from "./GuardianEmailForm";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import { GUARDIAN_EMAILS_LIMIT } from "../../../../domains/GuardianEmail";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { useResendConfirmationEmail } from "./useGuardianEmailsApi";

type Props = {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
};

export const GuardianEmailFormModal = ({ student, updateStudent }: Props) => {
  const {
    open: openModal,
    close: closeModal,
    isOpen: isModalOpen,
  } = useGuardianEmailModal();

  const { data: guardianEmails, isLoading } = useFetchGuardianEmails({
    studentId: student.id,
    enabled: isModalOpen,
  });

  const { isOpen: isEmailFormOpen, setIsOpen: setIsEmailFormOpen } =
    useEmailFormState({ guardianEmails });

  useSyncWithIndexState({ student, guardianEmails, updateStudent });

  return (
    <>
      <LinkButton onClick={openModal} className="p-0 text-green-500">
        {student.guardianEmails && student.guardianEmails.length > 0
          ? "編集"
          : "登録"}
      </LinkButton>

      <Modal
        onRequestClose={closeModal}
        isOpen={isModalOpen}
        className={styles.guardianEmailModal}
      >
        <Modal.Header onClose={closeModal}>
          <span>
            <Link to={`/students/${student.id}`}>
              <Text color="primary" as="span">
                {student.fullName}
              </Text>
            </Link>
            の保護者のメールアドレス登録
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.guardianEmailModal__body}>
            <p>
              登録されたメールアドレスに誤りがないか確認するため、認証メールを送信します。保護者側の認証が完了すると、登録したメールアドレスへの配信ができるようになります。
              <br />
              ※最大2件のメールアドレスを登録できます。
            </p>
            {!isLoading && guardianEmails !== undefined ? (
              <>
                <GuardianEmailList
                  student={student}
                  guardianEmails={guardianEmails}
                />
                {guardianEmails.length < GUARDIAN_EMAILS_LIMIT && (
                  <BlockRow marginTop="2.4rem">
                    {isEmailFormOpen ? (
                      <GuardianEmailForm student={student} />
                    ) : (
                      <AddNewEmailButton
                        onClick={() => {
                          setIsEmailFormOpen(true);
                        }}
                      />
                    )}
                  </BlockRow>
                )}
              </>
            ) : (
              <Loader loading />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const AddNewEmailButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <LinkButton color="primary" onClick={onClick} bold>
      <Flex gap="0.4" alignItems="center">
        <Icon name="icon-plus-tag" />
        <span>メールアドレス登録の追加</span>
      </Flex>
    </LinkButton>
  );
};

const GuardianEmailList = ({
  student,
  guardianEmails,
}: {
  student: Props["student"];
  guardianEmails: GuardianEmail[];
}) => {
  if (guardianEmails.length === 0) {
    return null;
  }

  return (
    <ul>
      {guardianEmails.map((email) => (
        <GuardianEmail
          student={student}
          email={email}
          key={`guardian-email-${email.id}`}
        />
      ))}
    </ul>
  );
};

const GuardianEmail = ({
  student,
  email,
}: {
  student: Props["student"];
  email: GuardianEmail;
}) => {
  const {
    onDelete,
    isDeleting,
    onResendConfirmationEmail,
    isResendingConfirmationEmail,
  } = useMutateGuardianEmail({
    student,
    email,
  });

  const [checkIconColor, statusTextColor]: Colors[] = email.confirmed
    ? ["green", "green"]
    : ["gray-normal", "red"];

  return (
    <li className={styles.guardianEmail}>
      保護者のメールアドレス：
      <Text as="span" color={statusTextColor}>
        {translateGuardianEmailStatus(email)}
      </Text>
      <div className={styles.guardianEmail__flex}>
        <Icon name="icon-success" color={checkIconColor} />
        <div className={styles.guardianEmail__flex__email}>
          <Text>{email.email}</Text>
        </div>
        <LinkButton onClick={onDelete} disabled={isDeleting}>
          <Icon name="icon-trash" />
          削除
        </LinkButton>
      </div>
      {email.confirmed === false && (
        <Flex marginTop="1rem" alignItems="center" gap="1.6">
          <Flex gap="1" marginTop="0" alignItems="center">
            <Text>{TimeHelper.fullFormat(email.confirmationSentAt)}</Text>
            <Text>送信済み</Text>
          </Flex>
          <Button
            onClick={onResendConfirmationEmail}
            disabled={isResendingConfirmationEmail}
            size="sm"
            variant="outline"
            className={styles.sendEmailButton}
            isRound
          >
            認証メールを再送信
          </Button>
        </Flex>
      )}
    </li>
  );
};

type UseMutateGuardianEmailProps = {
  student: StudentInterface;
  email: GuardianEmail;
};
const useMutateGuardianEmail = ({
  student,
  email,
}: UseMutateGuardianEmailProps) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { mutate: deleteGuardianEmail, isLoading: isDeleting } =
    useDeleteGuardianEmail({
      studentId: student.id,
      guardianEmailId: email.id,
    });

  const onDelete = () => {
    if (
      !window.confirm(
        "保護者のメールアドレスを本当に削除しますか？\n認証済みのメールアドレスの場合、再登録時に保護者側の認証確認が再度必要になります。",
      )
    ) {
      return;
    }

    deleteGuardianEmail(undefined, {
      onSuccess: () => {
        showSuccessMessage("メールアドレスを削除しました");
      },
      onError: () => {
        showErrorMessage("メールアドレスを削除できませんでした");
      },
    });
  };

  const {
    mutate: resendConfirmationEmail,
    isLoading: isResendingConfirmationEmail,
  } = useResendConfirmationEmail({
    studentId: student.id,
    guardianEmailId: email.id,
  });

  const onResendConfirmationEmail = () => {
    resendConfirmationEmail(undefined, {
      onSuccess: () => {
        showSuccessMessage(
          "保護者のメールアドレス宛に認証メールを再送信しました。",
        );
      },
    });
  };

  return {
    onDelete,
    isDeleting,
    onResendConfirmationEmail,
    isResendingConfirmationEmail,
  };
};

const useEmailFormState = ({
  guardianEmails,
}: {
  guardianEmails?: GuardianEmail[];
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (guardianEmails !== undefined && guardianEmails.length === 0) {
      setIsOpen(true);
    }
  }, [guardianEmails]);

  return {
    isOpen,
    setIsOpen,
  };
};

type UseSyncWithIndexStateProps = {
  student: StudentInterface;
  guardianEmails?: GuardianEmail[];
  updateStudent: (student: StudentInterface) => void;
};
const useSyncWithIndexState = ({
  student,
  guardianEmails,
  updateStudent,
}: UseSyncWithIndexStateProps) => {
  React.useEffect(() => {
    if (!guardianEmails) {
      return;
    }

    updateStudent({
      ...student,
      guardianEmails,
    });
  }, [guardianEmails]);
};
