import * as React from "react";
import styles from "./styles.scss";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import { useFetchBotMessageNotificationUnseenCount } from "../../../hooks/http/useFetchUnseenCount";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { MessageNotificationInterface } from "../../../interfaces/NotificationInterface";
import Icon from "../../atoms/Icon";
import HeaderPopup from "../HeaderPopup";
import HeaderNotificationsHeading from "../../atoms/HeaderNotificationsHeading";
import HeaderMessageNotificationList from "../HeaderMessageNotificationList";
import HeaderMenuFooter from "../../atoms/HeaderMenuFooter";
import { useFetchBotMessages } from "../../../hooks/http/useFetchBotMessages";

type Props = {
  sectionId: string;
} & WithRouterProps;

const HeaderBotMessageNotifications = (props: Props) => {
  const {
    listVisible,
    buttonRef,
    listRef,
    toggleListVisibility,
    unseenCount,
    messages,
    isMessageFetching,
  } = useHeaderBotMessageNotifications(props);
  const { sectionId } = props;

  return (
    <div className={styles.root}>
      <button
        onClick={toggleListVisibility}
        className={styles.button}
        ref={buttonRef}
      >
        <Icon name="icon-notice-bot" className={styles.iconMessage} />
        {renderBadge({ unseenCount: unseenCount?.count || 0 })}
      </button>
      {renderNotifications({
        listVisible,
        listRef,
        messages: messages ? messages.botMessageNotifications : [],
        isFetching: isMessageFetching,
        sectionId,
      })}
    </div>
  );
};

const useHeaderBotMessageNotifications = (props: Props) => {
  const [listVisible, setListVisible] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const listRef = React.useRef<HTMLSpanElement | null>(null);
  const [isEnabledFetchUnseenCount, setIsEnabledFetchUnseenCount] =
    React.useState(false);

  React.useEffect(() => {
    document.addEventListener("click", closeList);
    return () => {
      document.removeEventListener("click", closeList);
    };
  }, []);

  // 初回のレンダリング時に未読数を取得する
  React.useEffect(() => {
    setIsEnabledFetchUnseenCount(true);
  }, []);

  // ページ遷移時に未読数を取得する
  React.useEffect(() => {
    setIsEnabledFetchUnseenCount(true);
  }, [props.location.pathname]);

  const { data: unseenCount, error } =
    useFetchBotMessageNotificationUnseenCount({
      sectionId: props.sectionId,
      pathname: props.location.pathname,
      enabled: isEnabledFetchUnseenCount,
      onSuccess: () => setIsEnabledFetchUnseenCount(false),
    });

  const {
    data: messages,
    isFetching,
    error: messagesError,
  } = useFetchBotMessages({ sectionId: props.sectionId, enabled: listVisible });
  useQueryError(error || messagesError);

  const closeList = (e: any) => {
    if (
      buttonRef &&
      buttonRef.current &&
      buttonRef.current.contains(e.target)
    ) {
      return;
    }

    if (listRef && listRef.current && listRef.current.contains(e.target)) {
      return;
    }

    setListVisible(false);
  };

  const toggleListVisibility = () => {
    setListVisible(!listVisible);
  };

  return {
    listVisible,
    buttonRef,
    listRef,
    toggleListVisibility,
    unseenCount,
    messages,
    isMessageFetching: isFetching,
  };
};

type BadgeProps = {
  unseenCount: number;
};
const renderBadge = (props: BadgeProps) => {
  const unseenCountLabel =
    props.unseenCount > 999 ? "999+" : props.unseenCount + "";

  return props.unseenCount > 0 ? (
    <div className={styles.badge}>
      <div className={styles.number}>{unseenCountLabel}</div>
    </div>
  ) : null;
};

type NotificationsProps = {
  listVisible: boolean;
  listRef: React.RefObject<HTMLSpanElement> | null;
  messages: MessageNotificationInterface[];
  isFetching: boolean;
  sectionId: string;
};

const renderNotifications = (props: NotificationsProps) => {
  if (props.listVisible) {
    return (
      <span ref={props.listRef}>
        <HeaderPopup>
          <HeaderNotificationsHeading title={`アシスタントメッセージ`} />
          <HeaderMessageNotificationList
            messages={props.messages}
            loading={props.isFetching}
            type={"BotMessage"}
          />
          <HeaderMenuFooter
            path={`/sections/${props.sectionId}/message_threads`}
            label="もっと見る"
          />
        </HeaderPopup>
      </span>
    );
  } else {
    return null;
  }
};

export default withRouter<Props>(HeaderBotMessageNotifications);
