import { useInfiniteQuery } from "@tanstack/react-query";
import { LectureAttendanceStudentAnalyticsTable } from "../../../domains/Student";
import { createError, HTTPErrors } from "../../../errors";
import ApiResponse from "../../../interfaces/ApiResponse";
import ApiClient from "../../../api";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { AnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";
import { useSelectedTags } from "../../../hooks/filters/useSelectedTags";
import { toQueryString } from "../../../helpers/TimeHelper";
import { StudentFilterInterface } from "../../../interfaces/FiltersInterface";

type Response = ApiResponse<LectureAttendanceStudentAnalyticsTable>;
type Props = {
  sectionId: string;
  analyticsFilter: AnalyticsFilter;
  studentFilter: StudentFilterInterface | undefined;
};

export const useFetchStudentAnalytics = ({
  sectionId,
  analyticsFilter,
  studentFilter,
}: Props) => {
  const { jobIds, tagIds, smartTagIds } = useSelectedTags(
    studentFilter?.tagFilter.selectedTags,
  );
  return useInfiniteQuery<Response, HTTPErrors>(
    cacheKeyOf({ sectionId, analyticsFilter, studentFilter }),
    async ({ pageParam, signal }) => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/analytics/lecture_attendances/students`,
        {
          query: {
            initial_date:
              analyticsFilter?.endDate &&
              toQueryString(analyticsFilter.endDate),
            term: analyticsFilter?.term,
            order: analyticsFilter?.order,
            order_dir: analyticsFilter?.orderDir,
            page: pageParam,
            ...(studentFilter
              ? {
                  status: studentFilter.tagFilter.selectedStatusTags.map(
                    (status) => status.id,
                  ),
                  job: jobIds,
                  tag_ids: tagIds,
                  smart_tag: smartTagIds,
                }
              : {}),
          },
          signal,
        },
      );
      if (response.ok) {
        const json = (await response.json()) as {
          lectureAttendanceStudentsAnalyticsTable: ApiResponse<LectureAttendanceStudentAnalyticsTable>;
        };
        return json.lectureAttendanceStudentsAnalyticsTable;
      } else {
        throw await createError(response);
      }
    },
    {
      getNextPageParam,
      enabled: !!analyticsFilter,
    },
  );
};

const cacheKeyOf = ({ sectionId, analyticsFilter, studentFilter }: Props) => [
  "attedanceStudentAnalytics",
  sectionId,
  analyticsFilter,
  studentFilter,
];
