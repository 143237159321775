import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../../api";
import { cacheKeyOf } from "./useFetchBuildingsApi";
import { Building } from "../../../../domains/Building";
import {
  createError,
  HTTPErrors,
  UnprocessableEntityError,
  UnprocessableEntityErrorItem,
} from "../../../../errors";

type Props = {
  sectionId: string;
  building: Building | null;
};
type Params = {
  name: string;
  latitude: number;
  longitude: number;
  correction_range: number;
};

const request = (
  sectionId: string,
  params: Params,
  building: Props["building"],
) =>
  building
    ? api.patch(`/api/v1/sections/${sectionId}/buildings/${building.id}`, {
        building: params,
      })
    : api.post(`/api/v1/sections/${sectionId}/buildings`, { building: params });

export const useMutateBuildingApi = ({ sectionId, building }: Props) => {
  const client = useQueryClient();

  return useMutation<Building, HTTPErrors, Params, unknown>(
    async (params: Params) => {
      const res = await request(sectionId, params, building);

      if (res.ok) {
        const json = (await res.json()) as { building: Building };
        return json.building;
      }

      if (res.status === 422) {
        const json = await res.json();
        throw new UnprocessableEntityError(
          json.errors as UnprocessableEntityErrorItem[],
        );
      }

      throw await createError(res);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(cacheKeyOf(sectionId));
      },
    },
  );
};
