import {
  InfiniteData,
  QueryClient,
  QueryKey,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import ApiClient from "../../api";
import { DrillStudentProgress } from "../../domains/DrillStudentProgress";
import { createError, HTTPErrors } from "../../errors";
import ApiResponse from "../../interfaces/ApiResponse";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import SectionInterface from "../../interfaces/SectionInterface";
import { StudentFilterParams } from "../../helpers/FiltersHelper";

type UseFetchDrillProgress = {
  section: SectionInterface;
  materialCode?: string;
  order: "desc" | "asc";
  studentFilterParams: StudentFilterParams;
};

type ResponseData = ApiResponse<DrillStudentProgress[]>;

export const useFetchDrillProgress = ({
  section,
  materialCode,
  order,
  studentFilterParams,
}: UseFetchDrillProgress) => {
  const queryClient = useQueryClient();
  const baseURL = `/api/v1/sections/${section.id}/drill_learning_materials/${materialCode}/progress`;
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useInfiniteQuery<ResponseData, HTTPErrors>(
    cachekeyOf({ section, materialCode, order, studentFilterParams }),
    async ({ pageParam = 1, queryKey }) => {
      const response = await ApiClient.get(baseURL, {
        query: {
          page: pageParam,
          ...studentFilterParams,
          order: order,
        },
      });
      if (response.ok) {
        const json = await response.json();
        return removeDuplicatesFromApiData(
          queryClient,
          queryKey,
          json.drillLearningMaterialsProgresses,
        );
      }
      throw await createError(response);
    },
    {
      getNextPageParam,
      refetchOnWindowFocus: false,
      cacheTime: 0, // queryCacheと重複する生徒をレスポンスから除外する関係でクエリキーが変わるたびにキャッシュを捨てたい
    },
  );

  // NOTE: removeDuplicatesFromApiData() によりdataが0件になる場合があるため、その場合は次ページを取得する
  if (
    data?.pages.length === 1 &&
    data.pages[0].data.length === 0 &&
    hasNextPage
  ) {
    fetchNextPage();
  }

  useOnMainScrollAreaScroll(() => {
    if (!isLoading && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);
  return {
    data: data?.pages.flatMap((page) => page.data),
    isLoading: isLoading,
    isFetchingNextPage,
    error,
  };
};

const cachekeyOf = ({
  section,
  materialCode,
  order,
  studentFilterParams,
}: UseFetchDrillProgress) => [
  "drillProgress",
  section.id,
  materialCode,
  order,
  studentFilterParams,
];

// すでにクエリキャッシュにあるデータはレスポンスデータから除外する
// NOTE: (A)calciumに定着度をもつ生徒 と (B)棚登録したことがある生徒 がレスポンスとして含まれるが、(B)は(A)を包含するため重複が発生してしまう
//       (A)がつねに先にレスポンスとして返るため、除外されるのは(B)としての重複のみとなる
const removeDuplicatesFromApiData = (
  queryClient: QueryClient,
  queryKey: QueryKey,
  responseData: ResponseData,
) => {
  const queryCache =
    queryClient.getQueryData<InfiniteData<ResponseData>>(queryKey);
  const cachedStudentIds = queryCache?.pages.flatMap((page) => {
    return page.data.map((entry) => entry.student.id);
  });
  if (!cachedStudentIds) {
    return responseData;
  }

  responseData.data = responseData.data.filter(
    (entry) => !cachedStudentIds.includes(entry.student.id),
  );
  return responseData;
};
