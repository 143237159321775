import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../../api";
import { createError, HTTPErrors } from "../../../errors";
import { paths } from "../../../lib/api/v1";

type Props = {
  sectionId: string;
  studyTaskId: string;
};
export type StudyTaskContentsResponse =
  paths["/api/v1/sections/{section_id}/study_tasks/{study_task_hashid}/study_task_contents"]["get"]["responses"]["200"]["content"]["application/json"];

const URL =
  "/api/v1/sections/{section_id}/study_tasks/{study_task_hashid}/study_task_contents";

export const useFetchStudyTaskContents = ({
  sectionId,
  studyTaskId,
}: Props) => {
  return useQuery<StudyTaskContentsResponse, HTTPErrors>(
    [URL, sectionId, studyTaskId],
    async () => {
      const { data, response } = await boronClient.GET(URL, {
        params: {
          path: {
            section_id: sectionId,
            study_task_hashid: studyTaskId,
          },
        },
      });
      if (response.ok && data) {
        return data;
      }
      throw await createError(response);
    },
    {
      refetchOnWindowFocus: false,
      // NOTE: 開閉のたびにコンポーネントがrenderされてAPIリクエストが走らないようにキャッシュを無期限に設定
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};
