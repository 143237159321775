import {
  HeatMapTd,
  NoTextTd,
  Table,
  Td,
  Th,
  TitleTd,
} from "../../../components/features/AnalyticsTable";
import styles from "./StudentAnalyticsTable.scss";
import SortLabel from "../../../components/molecules/SortLabel";
import {
  calculateDeviation,
  toHeaderDate,
} from "../../../helpers/AnalyticsHelper";
import { AttendanceRate } from "./AttendanceRate";
import { JobHumanLabel } from "../../../components/features/JobHumanLabel";
import { useFetchStudentAnalytics } from "./useFetchStudentAnalytics";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { AnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import Loader from "../../../components/atoms/Loader";
import { EmptyResultText } from "./EmptyResultText";
import { StudentFilterInterface } from "../../../interfaces/FiltersInterface";
import { Link } from "react-router-dom";

type Props = {
  sectionId: string;
  analyticsFilter: AnalyticsFilter;
  studentFilter: StudentFilterInterface | undefined;
  changeOrder: (order: string | null) => void;
};

const useStudentAnalyticsTable = ({
  sectionId,
  analyticsFilter,
  studentFilter,
}: Props) => {
  const {
    data,
    isLoading,
    error,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchStudentAnalytics({
    sectionId,
    analyticsFilter,
    studentFilter,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage]);

  const analyticsTableColumns = data?.pages[0].data.columns;
  const analyticsTableRows = data?.pages.flatMap((page) => page.data.students);
  const allScores =
    data?.pages.flatMap((page) =>
      page.data.students.flatMap((student) => student.scores),
    ) ?? [];

  return {
    isLoading,
    allScores,
    analyticsTableColumns,
    analyticsTableRows,
    isFetching,
    isFetchingNextPage,
  };
};

export const StudentAnalyticsTable = ({
  sectionId,
  analyticsFilter,
  studentFilter,
  changeOrder,
}: Props) => {
  const {
    analyticsTableRows,
    analyticsTableColumns,
    allScores,
    isFetchingNextPage,
    isLoading,
  } = useStudentAnalyticsTable({
    sectionId,
    analyticsFilter,
    studentFilter,
    changeOrder,
  });

  if (analyticsTableRows?.length === 0)
    return (
      <EmptyResultText>該当する生徒が見つかりませんでした</EmptyResultText>
    );

  return (
    <>
      {analyticsFilter && analyticsTableColumns && analyticsTableRows && (
        <div className={styles.tableWrapper}>
          <Table>
            <thead>
              <tr>
                <Th colSpan={3}>名前</Th>
                <Th>
                  <SortLabel
                    label="出席率"
                    active={!analyticsFilter.order}
                    orderDirection={analyticsFilter.orderDir || "desc"}
                    onClick={() => changeOrder(null)}
                  />
                </Th>
                {analyticsTableColumns.map((column) => {
                  return (
                    <Th key={`section-header-${column}`}>
                      <SortLabel
                        label={toHeaderDate(column, analyticsFilter?.term)}
                        active={analyticsFilter.order === column}
                        orderDirection={analyticsFilter.orderDir || "desc"}
                        onClick={() => changeOrder(column)}
                      />
                    </Th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {analyticsTableRows.map((student) => {
                return (
                  <tr key={`student-row-${student.id}`}>
                    <NoTextTd>{student.rank}</NoTextTd>
                    <TitleTd>
                      <Link
                        to={{
                          pathname: `/students/${student.id}/analytics/lecture_attendance`,
                          search: window.location.search,
                        }}
                        className={styles.studentName}
                      >
                        {student.fullName}
                      </Link>
                    </TitleTd>
                    <Td>
                      <JobHumanLabel>{student.jobHuman}</JobHumanLabel>
                    </Td>
                    <Td>
                      <AttendanceRate rate={student.totalScore} />
                    </Td>
                    {analyticsTableColumns.map((_, columnIndex) => {
                      return (
                        <HeatMapTd
                          key={`student-column-${student.id}-${columnIndex}`}
                          score={student.scores[columnIndex]}
                          deviation={calculateDeviation(
                            allScores,
                            student.scores[columnIndex],
                          )}
                        >
                          <StudentLectureAttendanceCount
                            attendanceRate={student.scores[columnIndex]}
                            lecturePresenceCount={
                              student.lecturePresenceCount[columnIndex]
                            }
                            lectureTotalCount={
                              student.lectureTotalCount[columnIndex]
                            }
                          />
                        </HeatMapTd>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <Loader loading={isFetchingNextPage || isLoading} />
    </>
  );
};

const StudentLectureAttendanceCount = ({
  attendanceRate,
  lecturePresenceCount,
  lectureTotalCount,
}: {
  attendanceRate: number;
  lecturePresenceCount: number | undefined;
  lectureTotalCount: number | undefined;
}) => {
  return lectureTotalCount && lectureTotalCount > 0 ? (
    <>
      <div>
        <AttendanceRate rate={attendanceRate} />
      </div>
      <div>
        （{lecturePresenceCount}/{lectureTotalCount}件）
      </div>
    </>
  ) : (
    <>0件</>
  );
};
