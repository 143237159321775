import { useQuery } from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../../errors";
import ApiClient from "../../../api";
import SkillMasterInterface from "../../../interfaces/SkillMasterInterface";

export const useFetchSkillMasters = () => {
  return useQuery<SkillMasterInterface[], HTTPErrors>(
    ["/api/v1/skill_masters"],
    async () => {
      const response = await ApiClient.interruptGet(`/api/v1/skill_masters`);

      if (response.ok) {
        const json = await response.json();
        return json.skillMasters.data;
      }
      throw await createError(response);
    },
    {
      refetchOnMount: false,
    },
  );
};
