import SectionInterface from "../../../interfaces/SectionInterface";
import { useErrorDispatcher } from "../../../hooks/useErrorDispatcher";
import { matchHttpError } from "../../../errors";
import { useContentCourseDetailQuery } from "../../../hooks/http/useContentCoursesApi";
import { useQueryError } from "../../../hooks/http/useQueryError";

type UseSectionsSettingsContentCourseDetail = {
  section: SectionInterface;
  courseId: string | null;
};

export const useExistingCourse = (
  props: UseSectionsSettingsContentCourseDetail,
) => {
  const { sendNotFound, sendForbidden } = useErrorDispatcher();
  const { course, isLoading, error } = useContentCourseDetailQuery({
    onQueryError: (error) =>
      matchHttpError(error, {
        caseForbiden: sendForbidden,
        caseNotFound: sendNotFound,
      }),
    sectionId: props.section.id,
    courseId: props.courseId,
    disableRefetchOnWindowFocus: true,
  });
  useQueryError(error);

  return {
    course,
    isLoading,
  };
};
