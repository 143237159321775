import { useInfiniteQuery } from "@tanstack/react-query";
import ApiClient from "../../api";
import ApiResponse from "../../interfaces/ApiResponse";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { createError, HTTPErrors } from "../../errors";
import SectionLearningMaterialInterface from "../../interfaces/SectionLearningMaterialInterface";

export type UseFetchSectionStudentsProps = {
  sectionId: string;
};

type ResponseBody = ApiResponse<SectionLearningMaterialInterface[]>;

export const sectionLearningMaterialsQueryKey = ({
  sectionId,
}: UseFetchSectionStudentsProps) => [baseUrl(sectionId)];

export const useFetchSectionLearningMaterials = (
  props: UseFetchSectionStudentsProps,
) => {
  const path = baseUrl(props.sectionId);
  const result = useInfiniteQuery<ResponseBody, HTTPErrors>(
    sectionLearningMaterialsQueryKey(props),
    async ({ pageParam = 1 }) => {
      const res = await ApiClient.get(`${path}?page=${pageParam}`);
      if (res.ok) {
        const json = await res.json();
        return json.learningMaterials as ResponseBody;
      }
      throw await createError(res);
    },
    {
      getNextPageParam,
      retry: false,
    },
  );
  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data, totalCount: result.data?.pages[0].meta.totalCount };
};

const baseUrl = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/learning_materials`;
